import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-social-media-content',
  templateUrl: './social-media-content.component.html',
  styleUrls: ['./social-media-content.component.scss']
})
export class SocialMediaContentComponent implements OnInit {


  constructor(private modalService: NgbModal) { }
  @Input() videoName = '';
  @Input() influencerName = '';
  @Input() videoLikes = '';
  @Input() videoViews = '';
  @Input() videoWidth = 600;
  @Input() videoHight = 300;
  // innerWidth: any;
  // autoPlay = false;
  // myVideo: any;
  // isPlaying: Boolean = true;

  ngOnInit(): void {
  }

  // ngAfterViewInit(): void {
  //   this.myVideo = document.getElementById(this.videoName);
  //   this.innerWidth = window.innerWidth;
  //   if(this.innerWidth < 991){
  //     this.autoPlay = false;
  //     this.isPlaying = false;
  //     this.myVideo.pause();
  //   }else{
  //     this.autoPlay = true;
  //     this.isPlaying = true;
  //     this.myVideo.play();
  //   }
  // }

  

  openVerticallyCentered(content: any) {
    this.modalService.open(content, { centered: true, size: 'lg', scrollable: true });
  }


  // playPause() {
  //   if (this.myVideo.paused) {
  //     this.isPlaying = true;
  //     this.myVideo.play();
  //   }
  //   else {
  //     this.isPlaying = false;
  //     this.myVideo.pause();
  //   }
  // }

  // @HostListener('window:resize', ['$event'])
  // onResize(event: any) {
  //   this.innerWidth = window.innerWidth;
  //   if(this.innerWidth < 991){
  //     this.autoPlay = false;
  //     this.isPlaying = false;
  //     this.myVideo.pause();
  //   }else{
  //     this.autoPlay = true;
  //     this.isPlaying = true;
  //     this.myVideo.play();
  //   }
  // }

}
