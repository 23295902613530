<div class="page-start">
    <app-header></app-header>
    <div class="d-flex flex-column align-items-center main-title">
        <div class="headings">
            <h1>The Global Talent Agency and Platform</h1>
            <!-- <h2>Connecting brands with top influencers to create inspiring content</h2> -->
            <h2>Partner with top global content creators and produce creative and effective influencer marketing campaigns</h2>
        </div>

        <div class="row gap-5 align-items-center justify-content-center">
            <a href="/contact-us" target="_blank"
                class="btn rounded-pill book-demo-button">Book a demo</a>
        </div>
        <div class="page-start-video-container">
            <video width="280px" height="280px" (click)="playPause()" id="my_video_1">
                <source src="assets/home1.mp4#t=0.1" type="video/mp4" />
            </video>
            <div class="video-background" *ngIf="!isPlayed"></div>
            <button *ngIf="!isPlaying" (click)="playPause()"><img src="assets/images/play.svg"></button>
        </div>
    </div>
</div>


<div>
    <div class="content">
        <div class="col align-items-center justify-content-center achievements">
            <h2>Brand Ripplr Credentials</h2>
            <div class="row justify-content-around m-auto gap-5">
                <app-statistic-widget [label]="'Global Influencers and Celebrities'" [value]="'10M+'"></app-statistic-widget>
                <!-- <app-statistic-widget [label]="'Campaigns in MENA'" [value]="'2,000+'"></app-statistic-widget> -->
                <app-statistic-widget [label]="'Trusted Clients'" [value]="'500+'"></app-statistic-widget>
                <!-- <app-statistic-widget [label]="'Markets in MENA'" [value]="'15+'"></app-statistic-widget> -->
                <app-statistic-widget [label]="'Campaigns'" [value]="'6,000+'"></app-statistic-widget>
                <!-- <app-statistic-widget [label]="'Consumer Reach'" [value]="'100 million+'"></app-statistic-widget> -->

            </div>
        </div>
    </div>
</div>
<div class="bg-gray">
<div class="container" style="margin:50px auto 30px">
    <div class="col align-items-center justify-content-center">
        <h2 style="text-align:center; margin-bottom: 30px;">Why Brand Ripplr?</h2>
        <div class="row justify-content-between align-items-start gap-10">
            <app-sub-header class="col-lg-2" [section]="'home-grow'" [label]="'Home-Grown Platform'"
                [content]="'The first influencer marketing platform in MENA with bespoke technology and operations tailored and optimised for this region and worldwide.'">
            </app-sub-header>
            <app-sub-header class="col-lg-2" [section]="'influencer-network'" [label]="'Largest Global Influencer Network'"
                [content]="'Collaborate with 10M+ leading global content creators and celebrities.'">
            </app-sub-header>
            <app-sub-header class="col-lg-2" [section]="'campaign-manager'" [label]="'Campaign Management'"
                [content]="'Largest experienced campaign management team with excellent relationships with influencers, celebrities and agents'">
            </app-sub-header>
            <app-sub-header class="col-lg-2" [section]="'creative'" [label]="'Creative Department'"
                [content]="'Creative department to develop influencer marketing strategies and deliver fresh and innovative ideas with the latest social features.'">
            </app-sub-header>
            <app-sub-header class="col-lg-2" [section]="'negotitate'" [label]="'Influencer Negotiations & Pricing'"
                [content]="'We optimise partnership deals for clients and deliver cost saving with our strong bargaining power with influencers due to the sheer volume of campaigns we provide them'">
            </app-sub-header>

        </div>
    </div>
</div>
</div>
<div class="content">
    <div class="col align-items-center justify-content-center campaigns">
        <h2>Campaigns by Brand Ripplr</h2>
        <div class="row justify-content-around m-auto">
            <app-social-media-content *ngFor="let influencerContent of selectedInfluencersContents"
                [videoName]="influencerContent.videoName" [influencerName]="influencerContent.influencerName"
                [videoViews]="influencerContent.videoViews" [videoLikes]="influencerContent.videoLikes">
            </app-social-media-content>
        </div>
    </div>
</div>

<div class="bg-gray">
    <div class="content">
        <div class="align-items-center justify-content-center content-creators">
            <h2>Influencers working with Brand Ripplr</h2>
            <div class="influencer_category_scroll">
                <div class="influencer_categories">
                    <div *ngFor="let item of influencers | keyvalue" [ngClass]="{'active': activeInfluencerTab == item.key }" (click)="changeInfluencerTab(item.key)"><span>{{item.key}}</span></div>
                </div>
            </div>
            <div class="influencer_scroll">
                <div class="influencer_items">
                    <app-influencer-social-media-profile *ngFor="let influencer of influencers[activeInfluencerTab]" [influencer]="influencer" ></app-influencer-social-media-profile>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content">
    <div class="col align-items-center justify-content-center trusted-clients">
        <h2>Trusted Clients</h2>
        <div class="row justify-content-around">
            <app-trusted-client-logo *ngFor="let client of clients" [clientImage]="client"></app-trusted-client-logo>
        </div>
    </div>
</div>
<div class="content">
    <div class="col align-items-center justify-content-center testimonials-container">
        <h2>Client Testimonials</h2>
        <div class="row justify-content-around testimonials-flex">
            <app-testimonial *ngFor="let t of testimonials" [testimonial]="t.testimonial" [name]="t.name" [company]="t.company"></app-testimonial>
        </div>
    </div>
</div>