<ng-template [ngIf]="!showCookiesConsentBar">
    <div class="cookies-bar">
        <div class="container cookies-bar-text d-flex justify-content-between align-items-center p-3">
            <div class="col-sm-12 col-md-8">We use cookies to improve your experinse on our site. <br> Clicking the Continue button, or continuing to use the site, means you agree with our <a routerLink="/influencer-terms-use/" fragment="cookiesPolicy">Cookie policy</a>.</div>
            <div class="col-sm-12 col-md-3">
                <div class="buttons-row d-flex justify-content-between align-items-center">
                    <button class="btn btn-primary" (click)="closeCookiesConsent(true)">
                        Continue
                    </button>
                    <a (click)="closeCookiesConsent(false)">
                        <fa-icon [icon]="faTimes" style="font-size: 30px;"></fa-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
</ng-template>