import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {

  @Input() label:String = '';
  @Input() content:String = '';
  @Input() iconImage:String = '';
  
  constructor() { }

  ngOnInit(): void {
  }

}
