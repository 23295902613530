import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  showNav: boolean = false;

  toggleNav() {
    if(this.showNav == false) {
      this.showNav = true;
    } else {
      this.showNav = false;
    }
    console.log(this.showNav);
  }

  ngOnInit(): void {
  }

  openVerticallyCentered(content:any) {
    this.modalService.open(content, { centered: true , size: 'lg', scrollable:true});
  }
}
