<div class="page-start">
  <app-header></app-header>
  <div class="container d-flex flex-column align-items-center" style="color: black; margin-top: 50px">
    <p style="
        font-size: 48px;
        font-weight: bold;
        text-align: center;
        background: -webkit-linear-gradient(
          rgb(96, 73, 215),
          rgb(136, 77, 223)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      ">
      Contact Us
    </p>
    <div class="phone-email">
      <div>
        <a href="mailto:support@brandripplr.com">
          <fa-icon style="font-size: 20px;font-weight: bold;text-align: center;" [icon]="faEnvelope"></fa-icon> support@brandripplr.com
        </a>
      </div>
      <div>
        <a href="tel:+971526574408">
          <fa-icon style="font-size: 20px;font-weight: bold;text-align: center;" [icon]="faPhone"></fa-icon> +971 52 657 4408
        </a>
      </div>
    </div>
  </div>

</div>

<div class="container contact-us-form-wrapper" style="margin-bottom:20px">
  <div class="d-flex align-items-center justify-content-center">
    <div class="col-md-11 col-lg-9 col-sm-12">
      <div class="container contact-us-form-container">
        <div *ngIf="!isSubmitted">
          <form [formGroup]="formData" (ngSubmit)="onSubmit(formData.value)">
            <div class="form-group">
              <label>Are you Business or Influencer? <span class="text-red">*</span></label>
              <select class="form-control" formControlName="type">
                <option value="">Select</option>
                <option value="Business">Business</option>
                <option value="Influencer">Influencer</option>
              </select>
            </div>
            <div class="form-group" *ngIf="formData.value.type == 'Business'">
              <label>Company Name <span class="text-red">*</span></label>
              <input class="form-control" placeholder="Ex. Adidas" formControlName="company_name" />
            </div>
            <div class="form-group">
              <label>First Name <span class="text-red">*</span></label>
              <input class="form-control" placeholder="Ex. John" formControlName="first_name" />
            </div>
            <div class="form-group">
              <label>Last Name <span class="text-red">*</span></label>
              <input class="form-control" placeholder="Ex. Smith" formControlName="last_name" />
            </div>
            <div class="form-group">
              <label>Email <span class="text-red">*</span></label>
              <input class="form-control" type="email" placeholder="Ex. name@domain.com" formControlName="email" />
            </div>
            <div class="form-group">
              <label>Contact Number <span class="text-red">*</span></label>
              <input class="form-control" type="tel" placeholder="Ex. +971XXXXXXXXX" formControlName="contact_number" />
            </div>
            <div class="form-group">
              <label>Message <span class="text-red">*</span></label>
              <textarea class="form-control" rows="4" placeholder="Ex. Write your message"
                formControlName="message"></textarea>
            </div>
            <br />
            <button class="btn btn-primary contact-us-form-submit-button" [disabled]="formData.invalid">
              Submit
            </button>
          </form>
        </div>
        <div *ngIf="isSubmitted" class="submitted text-center" style="padding:50px 0">

          <h1>Thank you for getting in touch!</h1>
          <p>One of our colleagues will get back in touch with you soon!</p>
        </div>
        <div class="sending" *ngIf="isSending">
          <ngx-spinner></ngx-spinner>
        </div>
      </div>
    </div>
  </div>
</div>