import { Component, Input, OnInit } from '@angular/core';
import firebase from 'firebase'; 
import Timestamp = firebase.firestore.Timestamp;


@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss']
})
export class BlogCardComponent implements OnInit {

  @Input() articleTag:string = '';
  @Input() articleTitle:string = '';
  @Input() articleContent:string = '';
  @Input() articleShortText:string = '';
  @Input() articleDate?:string = '';
  @Input() articleImage:string = '';
  @Input() articleId:string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
