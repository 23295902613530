<div class="d-flex flex-column" class="blogCard">

    <div class="coverImageContainer">
    <img class="coverImage"
        src="{{articleImage}}">
    </div>
    <div class="blogCardBody">
        <div class="blogCardHeader">
            <a href="/blog/{{articleId}}">
                {{articleTitle}}
            </a>
        </div>
        <div class="blogCardContent">
            {{articleShortText}}
        </div>
        <div class="blogCardDate" *ngIf="articleDate">
            {{articleDate | date:'mediumDate' }}
        </div>
    </div>
</div>