import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { BrandsAndAgenciesComponent } from './pages/brands-and-agencies/brands-and-agencies.component';
import { FeaturesComponent } from './pages/features/features.component';
import { ServicesComponent } from './pages/services/services.component';
import { InfluencersComponent } from './pages/influencers/influencers.component';
import { StatisticWidgetComponent } from './pages/home/statistic-widget/statistic-widget.component';
import { SocialMediaContentComponent } from './pages/home/social-media-content/social-media-content.component';
import { InfluencerSocialMediaProfileComponent } from './pages/home/influencer-social-media-profile/influencer-social-media-profile.component';
import { TrustedClientLogoComponent } from './pages/home/trusted-client-logo/trusted-client-logo.component';
import { FeatureComponent } from './pages/features/feature/feature.component';
import { ServiceComponent } from './pages/services/service/service.component';
import { CampaignProcessComponent } from './pages/brands-and-agencies/campaign-process/campaign-process.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { LeadershipTeamMemberComponent } from './pages/about-us/leadership-team-member/leadership-team-member.component';
import { SubHeaderComponent } from './pages/home/sub-header/sub-header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';
import { SignupPopupButtonComponent } from './components/signup-popup-button/signup-popup-button.component';
import { environment } from "src/environments/environment";
// import { AngularFirestoreModule } from "@angular/fire/firestore";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppService } from './app.service';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogCardComponent } from './pages/blog/blog-card/blog-card.component';
import { ArticleComponent } from './pages/blog/article/article.component';
import { MomentModule } from 'ngx-moment';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { CookiesConsentComponent } from './footer/cookies-consent/cookies-consent.component';
import { RouterModule } from '@angular/router';
import { InfluenceForGoodComponent } from './pages/influence-for-good/influence-for-good.component';
import { AwardsComponent } from './pages/awards/awards.component';
import { TestimonialComponent } from './pages/home/testimonial/testimonial.component';








@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    SignInComponent,
    BrandsAndAgenciesComponent,
    InfluenceForGoodComponent,
    AwardsComponent,
    FeaturesComponent,
    ServicesComponent,
    InfluencersComponent,
    StatisticWidgetComponent,
    SocialMediaContentComponent,
    InfluencerSocialMediaProfileComponent,
    TrustedClientLogoComponent,
    TestimonialComponent,
    FeatureComponent,
    ServiceComponent,
    CampaignProcessComponent,
    AboutUsComponent,
    LeadershipTeamMemberComponent,
    SubHeaderComponent,
    ContactUsComponent,
    TermsOfUseComponent,
    SignupPopupButtonComponent,
    BlogComponent,
    BlogCardComponent,
    ArticleComponent,
    CookiesConsentComponent,
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AppRoutingModule,
    AngularFirestoreModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgbModule,
    MomentModule,
    FormsModule,
    AngularFireFunctionsModule,
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    RouterModule
  ],
  providers: [
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
