<div class="feature-container d-flex align-items-center justify-content-start" style="flex-direction: column;">
    <div class="icon">
        <img src="assets/images/{{iconImage}}.svg" height="50px" />
    </div>
    <div style="font-size: 24px; font-weight: bold;margin: 0px 0px 10px;">
        {{label}}
    </div>
    <div style="padding: 0 30px; font-size: 18px;">
        {{content}}
    </div>
</div>
