import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {

  constructor() { }
  @Input() name = '';
  @Input() company = '';
  @Input() testimonial = '';

  ngOnInit(): void {
  }

}
