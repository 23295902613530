import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-trusted-client-logo',
  templateUrl: './trusted-client-logo.component.html',
  styleUrls: ['./trusted-client-logo.component.scss']
})
export class TrustedClientLogoComponent implements OnInit {

  constructor() { }
  @Input() clientImage = '';
  @Input() clientName = '';

  ngOnInit(): void {
  }

}
