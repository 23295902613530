import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-campaign-process',
  templateUrl: './campaign-process.component.html',
  styleUrls: ['./campaign-process.component.scss']
})
export class CampaignProcessComponent implements OnInit {


  @Input() label:String = '';
  @Input() content:String = '';
  @Input() index:String = '';
  
  constructor() { }

  ngOnInit(): void {
  }

}
