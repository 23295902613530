<div class="page-start">
        <app-header></app-header>
        <div class="container d-flex flex-column align-items-center" style='color:black; margin-top: 100px;'>
                <p style="font-size: 48px;font-weight: bold; text-align: center;
        background: -webkit-linear-gradient(rgb(96, 73, 215), rgb(136, 77, 223));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;">Terms of Use</p>
        </div>
    </div>
    <div class="container">
    
        <div class="page-content">
                <p class="p2"><strong>TERMS OF USE</strong></p>
                <p class="p3">The following terms of use, or any future amendment thereof, (the &ldquo;Terms of
                        Use&rdquo;) govern your access to and use of: (1) the Brand Ripplr website located at
                        http://www.brandripplr.com (the &ldquo;Website&rdquo;); (2) the Brand Ripplr online platform
                        (and any application software (&ldquo;Application&rdquo;)) facilitating the matching of persons
                        offering to create promotional online media content (each, an &ldquo;Influencer&rdquo;) and
                        Campaign Initiators (as defined below);and (3) all other services provided by Brand Ripplr, as
                        described on the Website, (collectively, the &ldquo;Platform&rdquo;). These Terms of Use form an
                        agreement between Brand Ripplr, FZ LLC a free zone limited liability company established in
                        Creative City, Media Free Zone, Fujairah under License number 11151/2017, Fujairah, United Arab
                        Emirates (&ldquo;Brand Ripplr&rdquo;, &ldquo;us&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo;) and
                        you and shall be the sole agreement between us. By creating a User Account and/or by accessing
                        or using (the term &ldquo;use&rdquo; when used herein in respect of the Platform shall mean
                        access or use, and using shall have a corresponding meaning) the Platform or underlying services
                        you are accepting and agreeing to these Terms of Use, as they may be amended from time to time.
                        If you are not willing to be bound by these Terms of Use, you should not accept or create a User
                        Account and should not make any use of the Platform. If you are creating a User Account,
                        accessing or using the Platform on behalf of another person or a corporate entity or if you are
                        acting as an agent, you represent and warrant that you have the authority to bind such person or
                        entity to these Terms of Use. You are solely responsible for ensuring that the Terms of Use are
                        in compliance with applicable laws, rules and regulations applicable to you.</p>
                <p class="p4">The term &ldquo;you&rdquo; refers to the person or entity visiting the Platform, browsing
                        or otherwise using the Platform, or communicating with content creators or Influencers
                        registered with Brand Ripplr or communicating with individuals or businesses registered with
                        Brand Ripplr (each, a &ldquo;Campaign Initiator&rdquo;) for the purpose of creating promotional
                        online media content for the Campaign Initiators&rsquo; products or services (each, a
                        &ldquo;Campaign&rdquo;). The term &ldquo;you&rdquo; may refer to the Influencer or Campaign
                        Initiator or both as the context may require.</p>
                <p class="p3">The Platform is provided solely (the &ldquo;Permitted Use&rdquo;) to: (1) assist in
                        gathering information about the various types of Campaign opportunities and Campaign Initiators
                        or Influencers available on the Platform, including profiles, price ranges, Campaign
                        descriptions, or video of Influencers (each, a &ldquo;Campaign Initiator Profile&rdquo; or
                        &ldquo;Influencer Profile&rdquo; as applicable); (2) enable you to post information regarding
                        yourself and your Campaign request or to respond to any Campaign opportunities; (3) post
                        Submissions or reviews of Campaign Initiators or Influencers; (4) facilitate communication with
                        Campaign Initiators or Influencers with the objective of entering into a Campaign Agreement; and
                        (5) facilitate the transmission of payments from the Campaign Initiator to Influencers in line
                        with the Campaign Agreement (together the &ldquo;Platform Services&rdquo;).</p>
                <p class="p3">The Platform enables content creators or Influencers to, among other things, engage with
                        advertisers, marketers or their agents for the creation and distribution of content created by
                        Influencers.</p>
                <p class="p3">You agree that you are solely responsible for all communications between you and any
                        Influencer or Campaign Initiator (as applicable) through the Platform. Your election to share
                        your data and information (which may include your personally identifiable information) to
                        Campaign Initiators or Influencers, or solicitation or acceptance of a Campaign will serve as
                        your affirmative &ldquo;opt in&rdquo; to the disclosure by Brand Ripplr of your data and
                        information (which may include your personally identifiable information) to that Campaign
                        Initiator or Influencer.</p>
                <p class="p2"><strong>Changes to these Terms of Use and Platform</strong></p>
                <p class="p3">Except where prohibited by applicable law, Brand Ripplr reserves the right to change these
                        Terms of Use at any time without notice. Your continued access to or use of the Platform after
                        any changes to these Terms of Use indicates your acceptance of such changes. It is your
                        responsibility to review these Terms of Use regularly.</p>
                <p class="p3">Brand Ripplr reserves the right to change any information, material or content
                        (including,&nbsp;but not limited to, price, features, availability of Campaign Initiators or
                        Influencers, Campaign Initiator Profiles or Influencers Profiles, types of Campaigns, and
                        reviews of Campaigns and Campaign Initiators or Influencers) all designs, IPR, infrastructure
                        graphics, pictures, illustrations, software, artwork, video, music, sound, names, words, titles,
                        phrases, logos and marks displayed or provided on or through the Platform (the
                        &ldquo;Content&rdquo;) at any time, and from time to time, without notice.</p>
                <p class="p2"><strong>Use of the Platform</strong></p>
                <p class="p3">As a condition of your use of the Platform, you warrant, represent and covenant that: (a)
                        you have reached the age of legal majority in your jurisdiction of residence and in any event
                        you are over the age of 18; (b) you possess the legal authority to create a binding legal
                        obligation; (c) you shall use the Platform in accordance with these Terms of Use; and (d) all
                        information supp
    
    
                        ed by you on the Platform is true, accurate, current, not misleading and complete.</p>
                <p class="p3">Brand Ripplr retains the right, at its sole discretion, to deny access to anyone to the
                        Platform or the services it offers, at any time and for any reason, including, but not limited
                        to, for violation of these Terms of Use. You shall cease and desist from any such access or use
                        immediately upon request by Brand Ripplr.</p>
                <p class="p2"><strong>License Grants</strong>&nbsp;</p>
                <p class="p3">Subject to these Terms of Use, Brand Ripplr grants you a personal, revocable,
                        non-exclusive and non-transferable, non sub licensable license during the Term to permit you to
                        access and use the Platform in accordance with these Terms of Use. The Platform, and the
                        databases, software, hardware and other technology used by or on behalf of Brand Ripplr to
                        operate the Platform, and the structure, organisation, and underlying data, information and
                        software code thereof, constitute valuable trade secrets of Brand Ripplr. This Platform and any
                        portion hereof may not be reproduced, duplicated, copied, reverse engineered, downloaded, sold,
                        resold, visited, or otherwise exploited for any commercial purpose without the express written
                        consent of Brand Ripplr. You may not frame or utilise framing techniques to enclose any
                        trademark, logo or other proprietary information (including images, text, page layout and form)
                        of Brand Ripplr without our express written consent. You may not use any meta tags or any other
                        &ldquo;hidden text&rdquo; utilising Brand Ripplr&rsquo; name or trademarks without our express
                        written consent. Any unauthorised use terminates the license granted by Brand Ripplr.</p>
                <p class="p3">Subject to these Terms of Use, you grant to Brand Ripplr a perpetual, transferrable,
                        irrevocable, royalty-free, fully paid-up, worldwide and fully sub licensable license to access,
                        collect, store and use any Submissions, content, data, information, records and files that: (1)
                        you generate, load, transmit to or enter into the Platform; or (2) we collect from your local
                        computer system or from third-parties with your permission (other than otherwise stated in the
                        Terms of Use or Privacy Policy), and (in each case) including all results from processing such
                        data, including compilations, and derivative works thereof solely for the purpose of: (A)
                        providing the Platform Services; (B) complying with applicable law; and (C) Brand Ripplr&rsquo;s
                        reasonable audit and data retention policies. We may also disclose aggregated user statistics in
                        order to describe our services to current and prospective business partners, and to other third
                        parties for other lawful purposes.</p>
                <p class="p3">Certain content may include or be based on data, information or content from Campaign
                        Initiators or Influencers or other independent third party content providers (&ldquo;Third Party
                        Content&rdquo;). Brand Ripplr has not verified the accuracy of, and will not be responsible for
                        any errors or omissions in, any Third Party Content provided through the Platform. Except as set
                        forth in these Terms of Use, you are granted no licenses or rights in or to any Content, or any
                        IPR therein or related thereto.</p>
                <p class="p2"><strong>User Account</strong></p>
                <p class="p3">You will be required to successfully sign up for a user account (the &ldquo;User
                        Account&rdquo;) using the available interfaces at the Platform. You shall keep your User Account
                        details secure and shall not share your User Account with anyone else, and you shall not collect
                        or harvest any personal data of any other user of Brand Ripplr, including account names. You
                        acknowledge and agree that the User Account is created and used for the sole purpose of running
                        and managing legitimate Campaigns and purchasing Platform Services. By creating a User Account
                        you represent, warrant and covenant that you provide Brand Ripplr with accurate, truthful,
                        complete and not misleading. It is your obligation to keep your registration information
                        accurate and up to date. Failure to do so shall constitute a breach of these Terms of Use, which
                        may result in immediate cancellation of your User Account. Brand Ripplr reserves the right to
                        disable any User Account issued to you at any time in Brand Ripplr&rsquo;s sole discretion. If
                        Brand Ripplr disables access to a User Account issued to you, you may be prevented from
                        accessing Brand Ripplr, your account details or any Campaigns that are associated with your
                        account.</p>
                <p class="p3">You are responsible for safeguarding the password that you use to access the User Account
                        and for any activities or actions under your password, whether your password is with our service
                        or a third-party service. You are fully responsible for all liabilities and damages incurred
                        through the use of your User Account (whether lawful or unlawful) and that any transactions
                        completed through any User Account will be deemed to have been lawfully completed by you. If you
                        add other users to your User Account, (i) such users shall only be authorised users on behalf of
                        your company, or your respective client, and (ii) you should be solely liable to their activity
                        in the User Account, and shall ensure such activity is in compliance with these Terms of Use.
                </p>
                <p class="p3">You agree not to disclose your password to any third party. You must notify us immediately
                        upon becoming aware of any breach of security or unauthorised use of your User Account. Failure
                        to do so will constitute a material breach of these Terms of Use.</p>
                <p class="p2"><strong>Term and Termination</strong></p>
                <p class="p3">The Terms of Use and Privacy Policy are entered into as of the earlier of the date you
                        first download or install an Application, access or use the Platform or Content, or indicate
                        your acceptance by clicking a box that states you accept these Terms of Use and will continue
                        until terminated as set forth herein.</p>
                <p class="p3">We may suspend or terminate your access to and use of the Platform, or Platform Services
                        or any other services available on our Website, at our sole discretion, at any time and without
                        notice to you. You may cancel your User Account at any time by sending an email to us at
                        support@brandripplr.com.</p>
                <p class="p3">Upon termination or expiration of these Terms of Use for any reason: (1) all rights and
                        subscriptions granted to you will terminate; (2) you will immediately cease all use of and
                        access to the Platform and all Content; (3) you will immediately delete any Applications
                        downloaded or installed prior to termination; and (4) we may delete your User Account and any of
                        your Campaign generated content held by Brand Ripplr at any time, all provisions of these Terms
                        of Use which by their nature should survive will survive, including, without limitation,
                        ownership provisions, warranty disclaimers, license grants, limitations of liability, and
                        dispute resolution provisions.</p>
                <p class="p2"><strong>Campaign Agreements with Campaign Initiators</strong></p>
                <p class="p3">The Platform permits to view the profiles of Campaign Initiators and Influencers (as
                        applicable) who have registered on our Platform and have chosen to allow you to view their
                        Campaign Initiator Profiles or Influencer Profiles.</p>
                <p class="p3">If a Campaign Initiator selects an Influencer for a Campaign through the Platform, you may
                        separately enter into an agreement with that Campaign Initiator/Influencer on such terms and
                        conditions as may be agreed to between you and that Campaign Initiator/Influencer
                        (&ldquo;Campaign Agreement&rdquo;). For example, the Campaign Agreement may contain: (a) a
                        description of the services the Influencer will provide to the Campaign Initiator, the timing of
                        the posting and approval process; (b) payment terms; and (c) any other terms and conditions as
                        communicated between the Influencer and the Campaign Initiator through this Platform or
                        otherwise. You understand that services are performed for the Campaign Initiator, and not Brand
                        Ripplr, and that Brand Ripplr is not a party to and will be in no way responsible for the
                        performance of either the Influencer or the Campaign Initiator under any Campaign Agreement. We
                        will facilitate the transmission of payment from the Campaign Initiator on their instructions in
                        accordance with these Terms of Use. As an Influencer you further understand and agree that any
                        content submitted or generated through the Platform pursuant to a Campaign Agreement and
                        accepted by a Campaign Initiator shall remain publicly accessible through your social media
                        account(s) (as specified in the Campaign Agreement) for a minimum of six (6) months. Brand
                        Ripplr does not make any representations or warranties of any kind in respect of a Campaign
                        Initiator or a Campaign Agreement. Campaign Initiator/Influencer also agree to act in good faith
                        when negotiating and performing obligations under each Campaign Agreement.</p>
                <p class="p5">For transparency purposes, Campaign Initiators shall encourage Influencers to disclose the
                        sponsored relationship and/or the compensatory nature of the reviews of Campaign
                        Initiator&rsquo;s products during a Campaign.</p>
                <p class="p2"><strong>Facilitated Payment&nbsp;</strong></p>
                <p class="p2">If Brand Ripplr facilitates the transmission of any payments from the Campaign Initiator
                        to Influencers in connection with a Campaign Agreement (each, a &ldquo;Facilitated
                        Payment&rdquo;), Brand Ripplr may in its sole discretion withhold from any such Facilitated
                        Payment any amounts that are payable to Brand Ripplr by the applicable Campaign Initiator in
                        connection with that Campaign Agreement. Brand Ripplr is not your trustee or fiduciary in
                        respect of any Facilitated Payments or any Campaign Agreements. Brand Ripplr reserves the right,
                        at its sole discretion, to place a hold on a Facilitated Payment, if Brand Ripplr determines
                        that the funds may be subject to a breach of applicable law (e.g. Fraud or money laundering), or
                        if Brand Ripplr is required to cooperate with law enforcement.</p>
                <p class="p2">Influencers/Campaign Initiators hereby acknowledge and agree that:</p>
                <p class="p3">Influencer</p>
                <ol class="ol1">
                        <li class="li6"><span class="s1">you shall not, at any time, amend your remuneration agreed with
                                        the Campaign Initiator (as applicable) during a Campaign
                                        (&ldquo;Fee&rdquo;);</span></li>
                        <li class="li6"><span class="s1">if you fail to post approved Submissions or approved generated
                                        content on the agreed social media on the date and time (with a three (3)-hour
                                        grace period depending on your time zone) agreed with the Campaign Initiator and
                                        fail to share the live link on the Platform under the Campaign dashboard, you
                                        will not be eligible for the encashment of the Fee;</span></li>
                        <li class="li6"><span class="s1">if you remove the published post prior to the six (6)-month
                                        period stated above or such other date agreed between you and the Campaign
                                        Initiator you shall be liable to repay the Fee received and such additional
                                        charge amounting to at least the amount of the Fee along with any cost and
                                        expenses incurred by the Campaign Initiator and Brand Ripplr which shall become
                                        due and payable immediately to Brand Ripplr. Brand Ripplr reserves the right to
                                        set off existing or future Fees against any outstanding payment. If you fail to
                                        comply with such requirement within ten (10)-working days Brand Ripplr shall,
                                        all other rights reserved, have the right to cancel your User Account and take
                                        such actions as in its sole discretion it deems appropriate;</span></li>
                        <li class="li6"><span class="s1">your Wallet in the User Account section of the Platform will
                                        reflect the Fee you have earnt from the Campaign after all posts specified by
                                        the Campaign Initiator are published and linked on the Platform under the
                                        Campaign dashboard. You can then withdraw this balance in your wallet 14 to 45
                                        days after the Campaign has been published and the link shared on the
                                        Platform;</span></li>
                        <li class="li6"><span class="s1">if you request Fee withdrawal from your Wallet in your User
                                        Account you must withdraw the full available balance in your
                                        Wallet.&nbsp;&nbsp;There will be no partial payments or transfer made at any
                                        time; You must have a minimum of USD100 in your Wallet to request withdrawal.
                                        All transfer costs (including bank transfer charges, western union, paypal or
                                        any other payment transfer methods) will be deducted from the Fee an shall be
                                        borne by the Influencer;&nbsp;</span></li>
                        <li class="li6"><span class="s1">you agree that following the posting of an approved Submission,
                                        and for a period of not less than 12 hours for personal content Submissions or
                                        48 hours for commercial content Submissions, you will not make any other posting
                                        including on your personal social accounts without the prior written approval
                                        from Brand Ripplr and the Campaign Influencer;</span></li>
                        <li class="li6"><span class="s1">if a Campaign is cancelled before a Submission is approved from
                                        the Campaign Initiator, you will not be entitled to receiving the Fee;</span>
                        </li>
                        <li class="li6"><span class="s1">if a Campaign is cancelled after a Submission is approved but
                                        before you publish and link the post then you will not be entitled to receiving
                                        the Fee; and</span></li>
                        <li class="li6"><span class="s1">if a Campaign is cancelled after a Submission is approved and
                                        after you published and linked the post, you will be entitled to receiving the
                                        Fee.</span></li>
                </ol>
                <p class="p2" id="refundpolicy">Campaign Initiator</p>
                <ol class="ol1">
                        <li class="li6"><span class="s1">you shall not, at any time, amend the remuneration agreed with
                                        the Influencer during a Campaign;</span></li>
                        <li class="li6"><span class="s1">if a Campaign is cancelled after a Submission is approved and
                                        after payment of the Fee but before the Influencer publishes and links the post,
                                        the Fee including the compensation for Platform Services payable to Brand Ripplr
                                        will not be refunded;</span></li>
                        <li class="li6"><span class="s1">Brand Ripplr does not issue refunds. If however you believe
                                        that a refund is required please contact support@brandripplr.com. We will
                                        consider the request but cannot guarantee that a refund will be granted;</span>
                        </li>
                        <li class="li6"><span class="s1">the Campaign Initiator has up to 7-working days to raise any
                                        concerns with a published post, and shall provide a detailed explanation of the
                                        concern, Brand Ripplr shall consider the request and to the extent possible
                                        attempt to suggest a suitable way to deal with any such concern with no
                                        obligation or liability on Brand Ripplr to do or act or provide
                                        solutions;</span></li>
                        <li class="li6"><span class="s1">Brand Ripplr is not liable to any party if an Influencer
                                        removes his/her post prior to the six (6)-month period stated above or such
                                        other date as agreed between the Campaign Initiator and the Influencer;</span>
                        </li>
                        <li class="li6"><span class="s1">the Fees are exclusive of all applicable sales, use,
                                        value-added, and other taxes, and all applicable duties, tariffs, assessments,
                                        export and import fees, or other similar charges, and you will be responsible
                                        for payment of all such taxes, fees, duties, and charges. The value-added tax
                                        will be charged to you separately. You will make all payments of Fees to us free
                                        and clear of, and without reduction for, any withholding taxes or transfer
                                        charges.</span></li>
                </ol>
                <p class="p2"><strong>Six-Month Exclusivity</strong></p>
                <p class="p3">You (being a Campaign Initiator or an Influencer) hereby acknowledge and agree that you
                        will not for a period of six (6) months commencing on the later of: (i) the date when you last
                        communicated with a Campaign Initiator or Influencer as applicable&nbsp;&nbsp;&nbsp;in respect
                        of a Campaign, whether through the Platform or otherwise, or (ii) the execution date of a
                        Campaign Agreement entered into with a Campaign Initiator or Influencer as
                        applicable&nbsp;&nbsp;(the &ldquo;Commencement Date&rdquo;), neither you nor your directors,
                        employees, subcontractors, agents or affiliates will enter into an agreement directly or
                        indirectly with such Campaign Initiator or Influencer as applicable for the creation of any
                        promotional media content, subject to the following exemptions:</p>
                <ol class="ol1">
                        <li class="li6"><span class="s1">the agreement you are entering into with the Campaign Initiator
                                        is a Campaign Agreement, in respect of which Brand Ripplr will receive an agreed
                                        Fee as provided for thereunder; or</span></li>
                        <li class="li6"><span class="s1">you were engaged in good-faith negotiations with the Campaign
                                        Initiator or Influencer as applicable&nbsp;&nbsp;for the creation of that
                                        promotional media content on or before the Commencement Date; or</span></li>
                        <li class="li6"><span class="s1">you are renewing a pre-existing agreement with the Campaign
                                        Initiator or Influencer as applicable&nbsp;&nbsp;&nbsp;that expired after the
                                        Commencement Date.</span></li>
                </ol>
                <p class="p3">You hereby agree and undertake that you or any person representing you or acting on your
                        behalf, will not at any time, directly or indirectly contact, discuss, solicit or enter into any
                        arrangement other than as stated herein with the Campaign Initiator or Influencer as
                        applicable&nbsp;&nbsp;or any member, director, shareholder, employer, agent, contractor or
                        otherwise of the Campaign Initiator or Influencer as applicable&nbsp;&nbsp;other than through
                        the Platform and you shall indemnify Brand Ripplr for any breach of such undertaking including
                        without limitation for any loss or damage that may result from or any loss of profit or loss of
                        revenue in connection with such breach. You further agree to notify Brand Ripplr immediately in
                        the event that you are solicited by or contacted directly or indirectly by a Campaign Initiator
                        or Influencer as applicable or any of its representatives directly or indirectly other than
                        through the Platform. If you fail to comply with such obligations, we may, in our sole
                        discretion and without prejudice to any other remedy we may have, decide to, and without
                        prejudice to any other action available to us, terminate or cancel your User Account and forfeit
                        any Fees (as defined above) you may have outstanding with us. Any breach of these provisions
                        shall be deemed to constitute a material breach of the Terms of Use.&nbsp;</p>
                <p class="p2"><strong>Submissions, Reviews and Comments</strong></p>
                <p class="p3">By submitting content to the Platform or Brand Ripplr, or Campaign reviews, content,
                        questions, comments, suggestions, ideas, generated promotional online media content or similar
                        information (collectively, &ldquo;Submissions&rdquo;), you:</p>
                <ol class="ol1">
                        <li class="li6"><span class="s1">grant Brand Ripplr and its affiliates, a worldwide,
                                        non-exclusive, royalty-free, perpetual, transferable, irrevocable and fully
                                        sub-licensable right to:</span></li>
                        <ul class="ol2" style="list-style-type: none;">
                                <li class="li6"><span class="s1">a) use, reproduce, modify, adapt, translate, distribute,
                                                publish, create derivative works from and publicly display and perform
                                                such Submissions throughout the world in any media whatsoever, now known
                                                or hereafter devised; and</span></li>
                                <li class="li6"><span class="s1">b) use the name that you submit in connection with such
                                                Submission;</span></li>
                        </ul>
                        <li class="li6"><span class="s1">acknowledge that Brand Ripplr may choose, but is not required,
                                        to provide attribution of your Submissions (for example but without limitation,
                                        listing your name and city on a Campaign review that you submit or any such
                                        other referencing) at our discretion, and that such Submissions may be shared
                                        with other Campaign Initiators and others using our Platform;</span></li>
                        <li class="li6"><span class="s1">acknowledge and agree that Submissions are non-confidential and
                                        non-proprietary;</span></li>
                        <li class="li6"><span class="s1">acknowledge that Brand Ripplr may exercise its rights to use,
                                        publish or remove&nbsp;any Submissions without any notice to you;
                                        and&nbsp;</span></li>
                        <li class="li6"><span class="s1">will not use or re-use (directly or indirectly) or share with
                                        third party any Submissions or the content thereof (or any content similar to
                                        the Submissions) used for a specific Campaign for the purposes of any other
                                        Campaign on the Platform or on any other platform or social media without Brand
                                        Ripplr prior written approval. Any use to the contrary will constitute a
                                        material breach of these Terms of Use.</span></li>
                </ol>
                <p class="p3">FOR THE AVOIDANCE OF DOUBT, Submissions shall also include the approved generated content
                        posted on social media in connection with a Campaign&nbsp;pursuant to a Campaign Agreement.</p>
                <p class="p3">You further grant Brand Ripplr the right to pursue at law any person or entity that
                        violates your or Brand Ripplr&rsquo;s rights in the Submissions by a breach of these Terms of
                        Use.</p>
                <p class="p3">Submissions or any part thereof approved by the Campaign Initiator for use for any
                        specific Campaign will be uploaded by the Influencer on social media and the live link shall be
                        shared on the Platform as part of the Campaign dashboard in line with the terms of the Campaign
                        Agreement at the time and date agreed with the Campaign Initiator.</p>
                <p class="p3">The Influencer undertakes and agrees to&nbsp;maintain any Submissions submitted or
                        generated through the Platform pursuant to a Campaign Agreement and accepted by a Campaign
                        Initiator publicly accessible through her/his social media account(s) (as specified in the
                        Campaign Agreement) for a minimum period of six (6) months, or such greater term as specified in
                        the Campaign Agreement&nbsp;, any breach of such obligation will constitute a material breach
                        and will result, among other things, all rights reserved:</p>
                <ol class="ol1">
                        <li class="li6"><span class="s1">in the forfeiture of the Fee (as defined in these Terms of Use)
                                        and any such additional charge amounting to at least the amount of the Fee along
                                        with any cost and expenses incurred by the Campaign Initiator and Brand Ripplr
                                        which shall become due and payable immediately to Brand Ripplr; and</span></li>
                        <li class="li6"><span class="s1">should you fail to comply with the requirement under A above
                                        within ten (10) working days from you being notified of the same, cancelation of
                                        your User Account and any such other actions as Brand Ripplr in its sole
                                        discretion deems appropriate.</span></li>
                </ol>
                <p class="p2">Brand Ripplr reserves the right to set off existing or future Fees against any outstanding
                        payment.</p>
                <p class="p3">The Campaign Initiator acknowledges and agrees that any Submissions or approved
                        promotional online media content created by Influencer is owned by Influencer. Influencer grants
                        the Campaign Initiator the right to post or use Submissions as part of an advertisement campaign
                        on social media in which case the Campaign Initiator is required to provide attribution of
                        Submissions (for example, and without limitations, listing your name and city on a Campaign
                        Initiator or Campaign review that was submitted or such other referencing as may be agreed from
                        time to time). If the Campaign Initiator wishes to use the Submissions as part of an
                        advertisement other than on social media, Campaign Initiator shall provide attribution of
                        Submissions and obtain Influencer&rsquo;s approval prior to using the Submissions or approved
                        media content in an advertisement campaign.</p>
                <p class="p3">All data on the Platform relating to Influencers and Campaigns performance is updated
                        periodically. You can refer to the social media networks for real time statistics and data.</p>
                <p class="p2"><strong>Liability</strong></p>
                <p class="p3">Brand Ripplr takes no responsibility and assumes no liability for any Submissions posted
                        or submitted. Brand Ripplr has no obligation to post Submissions and reserves the right, in its
                        absolute discretion to determine which Submissions are published on the Platform. If you do not
                        agree to these terms, please do not provide us with any Submissions.</p>
                <p class="p2">You are fully responsible for the content of Submissions and you hereby warrant and
                        represent that:</p>
                <ol class="ol1">
                        <li class="li6"><span class="s1">you have all rights to the content submitted on the Platform,
                                        specifically including, but not limited to, reviews posted to the Platform,
                                        designs, infrastructure graphics, pictures, illustrations, software, artwork,
                                        video, music, sound, names, words, titles, phrases, logos and marks that may be
                                        displayed from time to time on an on-going basis;</span></li>
                        <li class="li6"><span class="s1">you have obtained all necessary licenses, permits or approvals
                                        that may apply to you in your jurisdiction; and</span></li>
                        <li class="li6"><span class="s1">your use of the Platform Services and Platform and each of your
                                        Submissions: A) does not infringe the rights of any other person or body; and B)
                                        complies with all applicable laws, regulations, codes and standards, including
                                        without limitation all applicable advertising standards and regulations
                                        concerning sponsored or advertorial content that might be applicable to you in
                                        your jurisdiction. Such obligation includes but is not limited to a
                                        responsibility on you to ensure that any Submission includes the necessary hash
                                        tag requirements that exist in respect of all applicable advertising standards
                                        and regulations.</span></li>
                </ol>
                <p class="p2"><strong>Ownership</strong></p>
                <p class="p3">All Content, including without limitation all designs, IPR, infrastructure graphics,
                        pictures, illustrations, software, artwork, video, music, sound, names, words, titles, phrases,
                        logos and marks displayed on the Platform, are owned or licensed by/to Brand Ripplr and are
                        protected by copyright, trade-mark and other intellectual property laws.<span
                                class="Apple-converted-space">&nbsp;</span></p>
                <p class="p3">Brand Ripplr expressly reserves all rights in the Platform and all materials provided by
                        Brand Ripplr that are not specifically granted to you. You acknowledge that all right, title and
                        interest in the Platform, all materials provided by Brand Ripplr in connection with these Terms
                        of Use (including the Content), and any update, improvements, additions, adaptation,
                        translation, customisation or derivative work thereof, and all intellectual property rights
                        therein will remain with Brand Ripplr (or third party suppliers, if applicable), and that the
                        Platform and all materials provided by Brand Ripplr hereunder are licensed and not
                        &ldquo;sold&rdquo; to you. Your right is limited to access the Platform and Content in line with
                        these Terms of Use. All Content and materials provided by Brand Ripplr on the Website are:
                        &copy;2017 Brand Ripplr, LLC.</p>
                        <section id="privacypolicy"></section>
                <p class="p2"><strong>Privacy Policy</strong></p>
                <p class="p2">Please visit https://brandripplr.com/privacy to review our current Privacy Policy, which
                        contains important information about our practices in collecting, storing, using and disclosing
                        your personal information, and which is hereby incorporated into and forms a part of these Terms
                        of Use. Our Privacy Policy, may be updated from time to time.</p>
                <p class="p2">Please note that Campaign Initiators may have additional privacy policies or statements
                        that govern their practices in collecting, storing, using and disclosing your personal
                        information. Please read those additional privacy policies or statements carefully. You hereby
                        represent and warrant to Brand Ripplr that you have familiarised yourself and agree with those
                        privacy policies or statements imposed by any Campaign Initiator with whom you elect to deal
                        through the Platform.</p>
                <p class="p3">Brand Ripplr takes the private nature of personal information seriously. This Privacy
                        Policy describes how we collect and what we do with the personal information we obtain when you
                        visit Brand Ripplr or use the Platform, and the services offered through Brand Ripplr
                        (collectively, the &ldquo;Services&rdquo;) and what controls you have. Please read this notice
                        carefully and do not hesitate to let us know if you have any questions. You may send your
                        questions to&nbsp;<a href="mailto:support@brandripplr.com"><span
                                        class="s2">support@brandripplr.com</span></a>.</p>
                <p class="p3">By using or accessing Brand Ripplr, you acknowledge and agree to the collection, use and
                        storage by Brand Ripplr, and all of its predecessors, successors, parents, subsidiaries,
                        affiliates, and past and present officers, directors, shareholders, investors, employees,
                        agents, attorneys, representatives licensors and information providers, of the Information (as
                        defined below) in accordance with the practices provided for in this Privacy Policy. Please note
                        that we may update this Privacy Policy as we change or develop the Website (as defined in the
                        Terms of Use) or the Services. Your continued use of the Website constitutes your acceptance of
                        the then-current privacy policy. For this reason, we recommend that you review the Privacy
                        Policy from time to time to ensure that you understand and are willing to accept the current
                        privacy practices of Brand Ripplr and that you carefully read and review any information before
                        submitting the same to Brand Ripplr. If you do not accept the practices set out in this privacy
                        policy you should not use the Website.</p>
                <p class="p3">Upon entering our Website or when registering your User Account (as defined in the Terms
                        of Use), you subscribe by default to our newsletter containing promotional content for the
                        Services. Should you no longer want to be subscribed to receive our newsletter, you can
                        unsubscribe from the newsletter by clicking the &ldquo;Unsubscribe&rdquo; link at the bottom of
                        any received newsletter.</p>
                <p class="p2"><strong>THE INFORMATION WE MAY COLLECT FROM YOU</strong></p>
                <p class="p3">As is true of most websites, we gather certain information automatically and store it in
                        log files or private databases.</p>
                <p class="p3">We may collect and process the following information (the &ldquo;Information&rdquo;):</p>
                <ul class="ul1">
                        <li class="li6"><span class="s1">information that you provide by completing forms on Brand
                                        Ripplr, including if you register as a user of Brand Ripplr subscribe to any
                                        service, upload or submit any material via Brand Ripplr, request any information
                                        or enter into any competition or promotion we may sponsor;</span></li>
                        <li class="li6"><span class="s1">in connection with an account sign-in facility, your password
                                        and log-in details;</span></li>
                        <li class="li6"><span class="s1">your preferences, which help us provide you with more tailored
                                        recommendations;</span></li>
                        <li class="li6"><span class="s1">communications you send to us, for example to report a problem
                                        or to submit queries, concerns or comments regarding Brand Ripplr or its
                                        Content;</span></li>
                        <li class="li6"><span class="s1">information from surveys that we may, from time to time, run on
                                        Brand Ripplr for research purposes, if you choose to respond to them;</span>
                        </li>
                        <li class="li6"><span class="s1">details of your visits to Brand Ripplr, the resources you
                                        access and any data you download;</span></li>
                        <li class="li6"><span class="s1">personal Identification Information in a variety of ways,
                                        including, but not limited to, when you visit our Website, register, subscribe
                                        to the newsletter, and in connection with other activities, services, features
                                        or resources we make available on our Website. You may be asked for, as
                                        appropriate, name, email address, mailing address, phone number, Bank account
                                        information. We will collect personal identification information from you only
                                        if you voluntarily submit such information to us. You can always refuse to
                                        supply personally identification information, except that it may prevent you
                                        from engaging in certain Website related activities or services; and</span></li>
                        <li class="li6"><span class="s1">non-Personal Data. For purposes of this Privacy Policy,
                                        &ldquo;Non-Personal Data&rdquo; means information that does not directly
                                        identify you. Additionally, Non-Personal Data means &ldquo;aggregate&rdquo; and
                                        &ldquo;de-personalised&rdquo; information, which is data we collect about the
                                        use of the Services, from which any personally identifiable data has been
                                        removed. We may use tools or third party analytical software to automatically
                                        collect and use certain Non-Personal Data that does not directly enable us to
                                        identify you. The types of Non-Personal Data we may collect and use include, but
                                        are not limited to: (i) device properties, including, but not limited to IP
                                        address, browser type, Internet Service Provider, referring/exit pages,
                                        operating system, date/time stamp, clickstream data, anonymously tracked on-page
                                        events, Media Access Control (&ldquo;MAC&rdquo;) address and unique device
                                        identifier or other device identifier (&ldquo;UDID&rdquo;); (ii) device software
                                        platform and firmware; (iii) mobile phone carrier; (iv) geographical data such
                                        as post code and coarse location; (v) other non-personal data as reasonably
                                        required by Brand Ripplr to enhance the Platform Services; and Information
                                        obtained from your social networking profile, if you log-in via a social
                                        networking site.</span></li>
                </ul>
                <p class="p3">You are under no obligation to provide any Information. However, if you should choose to
                        withhold requested Information, we may not be able to provide you with certain Services.</p>
                <p class="p2"><strong>HOW WE USE YOUR INFORMATION</strong></p>
                <p class="p2">We will use the Information to:</p>
                <ul class="ul1">
                        <li class="li6"><span class="s1">help us to build a profile of your preferences so we can offer
                                        you recommendations and services you are interested in;</span></li>
                        <li class="li6"><span class="s1">track users movement around the website;</span></li>
                        <li class="li6"><span class="s1">analyse user behaviour on page;</span></li>
                        <li class="li6"><span class="s1">administer your account with us;</span></li>
                        <li class="li6"><span class="s1">contact you by email when necessary;</span></li>
                        <li class="li7"><span class="s1">improve the layout and/or content of the pages of the Website
                                        and customise them for users;</span></li>
                        <li class="li7"><span class="s1">carry out research on our users&rsquo; demographics;</span>
                        </li>
                        <li class="li7"><span class="s1">disclose to lawful authorities when required to do so by
                                        law;</span></li>
                        <li class="li7"><span class="s1">to contact you in connection with providing the Services, to
                                        verify your credit card payment information;</span></li>
                        <li class="li7"><span class="s1">send you newsletters and recommendations;</span></li>
                        <li class="li7"><span class="s1">allow advertisements on the Website to be targeted, to the
                                        users for whom they are most pertinent;</span></li>
                        <li class="li7"><span class="s1">suggest connections for other users that are connected to you;
                                        and</span></li>
                        <li class="li7"><span class="s1">enhance your user experience in general.</span></li>
                </ul>
                <p class="p3">We may also occasionally contact you to obtain feedback on a specific Platform Service or
                        suggest social connections.</p>
                <p class="p3">For certain Platform Services, we also request credit card or other payment account
                        information, which is maintained by our payment processor in encrypted form on secure servers.
                        We do not share this information with any third party except the companies/bank responsible for
                        processing our credit card transactions.</p>
                <p class="p3">We may combine the Information you submit under your account with information from other
                        Brand Ripplr services or Third Parties Services (as defined below) in order to provide you with
                        a better experience and to improve the quality of our Services. For certain Services, we will
                        give you the opportunity to opt out of combining such information.</p>
                <p class="p3">We do not sell, trade, or rent your personal identification information to others. We may
                        share generic aggregated demographic information not linked to any personal identification
                        information regarding visitors and users with our business partners, trusted affiliates and
                        advertisers for the purposes outlined in this Privacy Policy or our Terms of Use.</p>
                <p class="p2"><strong>No Unlawful or Prohibited Use</strong></p>
                <p class="p3">You shall not, without Brand Ripplr&rsquo;s prior written permission, use the Platform and
                        the Content (as defined in the Terms of Use) for purposes other than the Permitted Use. Without
                        limiting the generality of the foregoing, you shall not, and shall not permit anyone else to:
                </p>
                <ol class="ol1">
                        <li class="li6"><span class="s1">&ldquo;frame&rdquo;, &ldquo;mirror&rdquo; or otherwise
                                        incorporate the Platform or the Content or any part thereof on any commercial or
                                        non-commercial website;</span></li>
                        <li class="li6"><span class="s1">access, monitor or copy any part of the Platform or the Content
                                        using any robot, spider, scraper or other automated means or any manual process
                                        for any purpose without our express written permission;</span></li>
                        <li class="li6"><span class="s1">violate the restrictions in any robot exclusion headers on the
                                        Content or the Platform or bypass or circumvent other measures employed to
                                        prevent or limitaccess to the Platform;</span></li>
                        <li class="li6"><span class="s1">take any action that imposes, or may impose, in Brand
                                        Ripplr&rsquo;s discretion, an unreasonable or disproportionately large load on
                                        the Platform;</span></li>
                        <li class="li6"><span class="s1">deep-link to any portion of the Platform for any
                                        purpose;</span></li>
                        <li class="li6"><span class="s1">remove (or permit anyone else to remove) any watermarks, labels
                                        or other legal or proprietary notices included in any Campaign, the Platform or
                                        the Content;</span></li>
                        <li class="li6"><span class="s1">modify or attempt to modify (or permit anyone else to modify or
                                        attempt to modify) the Platform or any Campaigns, including any modification for
                                        the purpose of disguising or changing any indications of the ownership or source
                                        of the Platform or a Campaign;</span></li>
                        <li class="li6"><span class="s1">use the Platform or any Campaign or Content as part of any
                                        service for sharing, lending or multi-person use, or for the purpose of any
                                        other institution, except as specifically permitted by an applicable Campaign
                                        Agreement and only in the exact manner specified and enabled by therein;</span>
                        </li>
                        <li class="li6"><span class="s1">attempt to, assist, authorise or encourage others to
                                        circumvent, disable or defeat any of the security features or components, such
                                        as digital rights management software or encryption, that protect the
                                        Platform;</span></li>
                        <li class="li6"><span class="s1">copy, reproduce, modify, distribute, transfer, sell, publish,
                                        broadcast, perform, transmit, publish, license or circulate in any form any part
                                        of the Platform or the Content;</span></li>
                        <li class="li6"><span class="s1">create derivative works based on the Platform or the Content,
                                        in whole or in part, or to decompile, disassemble, reverse engineer or other
                                        exploit any part of the Platform or the Content;</span></li>
                        <li class="li6"><span class="s1">use or access the Platform in a manner that violates the rights
                                        (including, but not limited to intellectual property rights) of any third party;
                                        or</span></li>
                        <li class="li6"><span class="s1">upload to or transmit through the Platform any information,
                                        images, text, data, media or other content that is offensive, pornographic,
                                        hateful, obscene, defamatory or violates any laws or regulations (including but
                                        not limited to criminal laws and regulations), in each case as determined by
                                        Brand Ripplr in its sole discretion,</span></li>
                        <li class="li6"><span class="s1">post, upload, publish, submit or transmit any Content
                                        that:</span></li>
                </ol>
                <p class="p8">(i) infringes, misappropriates or violates a third party&rsquo;s patent, copyright,
                        trademark, trade secret, moral rights or other intellectual property rights, or rights of
                        publicity or privacy;</p>
                <p class="p8">(ii) violates, or encourages any conduct that would violate, any applicable law or
                        regulation or would give rise to civil liability;</p>
                <p class="p9">(iii) is fraudulent, false, misleading or deceptive;</p>
                <p class="p10">(iv) is defamatory, obscene, pornographic, vulgar or offensive including but not limited
                        to about the brand, the client, the Campaign or Brand Ripplr;</p>
                <p class="p11">(v)<span class="Apple-converted-space">&nbsp; </span>promotes discrimination, bigotry,
                        racism, hatred, harassment or harm against any individual or group;</p>
                <p class="p12">(vi) may be perceived as political or politically motivated;</p>
                <p class="p11">(vii) is violent or threatening or promotes violence or actions that are<span
                                class="Apple-converted-space">&nbsp; </span>threatening to any person or entity; or</p>
                <p class="p8">(viii) promotes illegal or harmful activities or substances, contains or <span
                                class="Apple-converted-space">&nbsp; </span>promotes alcohol, tobacco or shisha or
                        anything that is deemed harmful or illegal by applicable law or any competent regulatory
                        authority.</p>
                <p class="p3">You shall be solely liable for any damages resulting from any violation of the foregoing
                        restrictions, or any other harm resulting from your posting of Content to the Platform. You
                        acknowledge that Brand Ripplr may exercise its rights (e.g. use, publish, store, delete) to use
                        any Submissions without any notice to you.</p>
                <p class="p2">Clear and Prominent Disclosure of Material Connections With Campaign Initiator</p>
                <p class="p2">You are required to follow the Federal Trade Commission&rsquo;s Endorsement Guides. In the
                        event a Campaign Initiator engages you for a Campaign, you understand and agree that you are
                        required to clearly and conspicuously disclose any material connection between you and the
                        Campaign Initiator. Material connections include, but are not necessarily limited to, the
                        Campaign Initiator providing you with something of value, such as free use of products or
                        services. In general, disclosures should be:</p>
                <ul class="ul1">
                        <li class="li7"><span class="s1">in clear and unambiguous language;</span></li>
                        <li class="li7"><span class="s1">as close as possible to the native ads to which they
                                        relate;</span></li>
                        <li class="li7"><span class="s1">in the same medium as the ad, for instance, in the video or in
                                        the Twitter post;</span></li>
                        <li class="li7"><span class="s1">in a font and color that&rsquo;s easy to read;</span></li>
                        <li class="li7"><span class="s1">in a shade that stands out against the background;</span></li>
                        <li class="li7"><span class="s1">for video ads, on the screen long enough to be noticed, read,
                                        and understood;<span class="Apple-converted-space">&nbsp;</span></span></li>
                        <li class="li6"><span class="s1">for audio disclosures, read at a cadence that&rsquo;s easy for
                                        consumers to follow and in words consumers will understand; and</span></li>
                        <li class="li6"><span class="s1">as an Influencer, it is your responsibility to understand and
                                        abide by the requirement imposed on you by your local authority and country
                                        legislation and to ensure that a clear and conspicuous disclosure is made each
                                        and every time you create content for a Campaign.</span></li>
                </ul>
                <p class="p3">If Brand Ripplr learns of Campaigns you create that do not, in our sole discretion, abide
                        by the local authority and country legislation endorsement disclosure guides, we may require you
                        to add appropriate disclosures, and we may require you to suspend the Campaign until such
                        disclosures are added. Repeated failure to include appropriate disclosures in your Campaigns, or
                        your failure to add disclosures upon request by Brand Ripplr, may result in termination of your
                        User Account.</p>
                <p class="p2"><strong>Fake Followers, Spam Accounts and Fake Engagement<span
                                        class="Apple-converted-space">&nbsp;</span></strong></p>
                <p class="p13">Influencers shall refrain from acquiring fake followers and fake engagement and as far as
                        possible screen and filter fake followers and spam accounts in order to minimise them. You
                        should immediately notify Brand Ripplr, if you aware or become aware of fake followers and/or
                        spam accounts that are following and/or engaging on your social media channels. <span
                                class="Apple-converted-space">&nbsp;</span></p>
                <p class="p2"><strong>Minors</strong></p>
                <p class="p3">Brand Ripplr does not knowingly collect any information from persons under the age of 13.
                        If we determine that any content has been uploaded to the Website or Application by someone
                        under the age of 13, it will be removed. If you are between the ages of 13 and 18, please ask
                        your parent&rsquo;s permission before&nbsp;visiting the Platform, browsing&nbsp;the Website, the
                        Application, the Platform Services or any other services. We encourage parents and legal
                        guardians to monitor their children&rsquo;s Internet usage and to help enforce our Privacy
                        Policy by instructing their children never to provide personally identifiable information on the
                        Website without their permission. If you have reason to believe that a child under the age of 13
                        has provided personally identifiable information to us through the Website, please contact
                        us&nbsp;<a href="mailto:support@brandripplr.com"><span
                                        class="s2">support@brandripplr.com</span></a>, and we will endeavor to delete
                        that information from our databases.</p>
                <p class="p3">To be an Influencer you represent and warrant that you are above 18 years old. If we
                        determine that you have misrepresented your age Brand Ripplr shall immediately terminate your
                        User Account and the Platform Services and any other services that we may provide from time to
                        time.</p>
                <p class="p2"><strong>Third Party Websites</strong></p>
                <p class="p3">The Platform may provide links to third party websites. Brand Ripplr does not endorse the
                        information contained on those web sites or guarantee their quality, accuracy, reliability,
                        completeness, currency, timeliness, non-infringement, merchantability or fitness for any
                        purpose. The content in any linked web site is not under Brand Ripplr&rsquo;s control, and if
                        you choose to access any such web site, you do so entirely at your own risk.</p>
                <p class="p3">Brand Ripplr may enable you to link your User Account with a valid account on a third
                        party social networking, email or content service such as Facebook, YouTube, or Twitter, (such
                        service, a &ldquo;Third-Party Service&rdquo; and each such account, a &ldquo;Third-Party
                        Account&rdquo;) by allowing Brand Ripplr to access your Third-Party Account, as is permitted
                        under the applicable terms and conditions that govern your use of each Third-Party Account. You
                        represent that you are entitled to disclose your Third-Party Account login information to Brand
                        Ripplr and/or grant Brand Ripplr access to your Third-Party Account (including, but not limited
                        to, for use for the purposes described herein) without breach by you of any of the terms and
                        conditions that govern your use of the applicable Third-Party Account and without obligating
                        Brand Ripplr to pay any fees or making Brand Ripplr subject to any usage limitations imposed by
                        such third-party service providers.</p>
                <p class="p3">By granting Brand Ripplr access to any Third-Party Accounts, you understand that Brand
                        Ripplr may access, make available and store (if applicable) any information, data, text,
                        software, music, sound, photographs, graphics, video, messages, tags and/or other materials
                        accessible that you have provided to and/or stored in your Third-Party Account (&ldquo;TPS
                        Content&rdquo;) so that it is available on and through the Brand Ripplr Platform via your User
                        Account. Depending on the Third-Party Accounts you choose and subject to the privacy settings
                        that you have set in such Third-Party Accounts, personally identifiable information that you
                        post to your Third-Party Accounts may be available on and through your User Account on the Brand
                        Ripplr Platform. You hereby authorise Brand Ripplr to retrieve information from, and submit
                        information to, such Third Party Services at your request; and to provide services relating to
                        any information we retrieve from those Third-Party Accounts. Please note that if a Third-Party
                        Account or associated service becomes unavailable or Brand Ripplr&rsquo;s access to such
                        Third-Party Account is terminated by the third-party service provider, then TPS Content will no
                        longer be available on and through the Brand Ripplr materials. You have the ability to disable
                        the connection between your User Account and your Third-Party Accounts at any time by accessing
                        the &ldquo;Profile&rdquo; section. In most cases, we do not actually store the entirety of your
                        TPS Content, but instead we collect and store the metadata associated with such TPS Content. You
                        hereby authorised such collection and storage.</p>
                <p class="p3">Relationship with third-party services: Please note that your relationship with the
                        third-party service providers associated with your third-party accounts is governed solely by
                        your agreement(s) with such third-party service providers, and Brand Ripplr disclaims any
                        liability for personally identifiable information that may be provided to it by such third-party
                        service providers in violation of the privacy settings that you have set in such third-party
                        accounts.</p>
                <p class="p3">Brand Ripplr makes no effort to review any TPS Content for any purpose, including but not
                        limited to, for accuracy, legality or non-infringement, and Brand Ripplr is not responsible for
                        any TPS Content. You should note that a Third-Party Service may change or amend its guidelines
                        and our access to it at any time, and we cannot guarantee that our Services will always include
                        a connection to such Third Party Service.</p>
                <p class="p3">We may hire other companies to perform certain business related functions such as for
                        Platform Services or processing payments on our behalf. Our payment-processing partner may
                        request that you provide certain personal data (e.g. a valid government issued ID, your legal
                        name, address, and date of birth) for the purpose of making payment through its financial
                        institutions and complying with any federal and state laws and regulations. They may also
                        communicate directly with you regarding any issues with a payment. Any information provided to
                        our third party will be confidential and only be used for intended purposes. You must provide us
                        with accurate, complete information and it is your responsibility to update and maintain changes
                        to that information. We are entitled to rely on any information you provide to us.</p>
                <p class="p3">As we develop our business, we might sell or buy businesses or assets. In the event of a
                        corporate sale, merger, reorganisation, dissolution or similar event, personal data may be part
                        of the transferred assets.</p>
                <p class="p2"><strong>Viruses</strong></p>
                <p class="p3">The downloading and viewing on the Website is done at your own risk. Brand Ripplr cannot
                        and does not guarantee or warrant that the Platform or the Content are compatible with your
                        computer system or that the Platform or the Content, or any links from the Platform or the
                        Content, shall be free of viruses, worms, trojan horses or disabling devices or other code that
                        manifests contaminating or destructive properties. You are responsible for implementing
                        safeguards to protect the security and integrity of your computer system, and you are
                        responsible for the entire cost of any service, repairs or connections of and to your computer
                        system that may be necessary as a result of your use of the Platform.</p>
                <p class="p2"><strong>Communications and Confidentiality</strong></p>
                <p class="p3">Brand Ripplr does not guarantee the confidentiality of any communications made by you
                        through the Platform. Although Brand Ripplr generally adheres to the accepted industry practices
                        in securing the transmission of data to, from and through the Platform, you understand, agree
                        and acknowledge that Brand Ripplr cannot and does not guarantee the security of data transmitted
                        over the Internet or public networks in connection with your use of the Platform or any
                        Campaigns.</p>
                <p class="p3">You hereby acknowledge and agree that by using our Platform Services you will be privy to
                        confidential information.</p>
                <p class="p3">You undertake to each of Brand Ripplr, and the Influencer or the Campaign Initiator as
                        applicable that you shall:</p>
                <ol class="ol1">
                        <li class="li6"><span class="s1">keep the Confidential Information secret and confidential and
                                        shall take all necessary steps to preserve its confidentiality;</span></li>
                        <li class="li6"><span class="s1">not disclose or make available any Confidential Information to
                                        any person, except as permitted by these Terms of Use or a Campaign Agreement
                                        (to the terms relating to such agreement)&nbsp;&nbsp;and provided that
                                        it:</span></li>
                        <ol class="ol2" type="a">
                                <li class="li6"><span class="s1">informs the permitted recipient of the confidential
                                                nature of the Confidential Information before it is disclosed;
                                                and</span></li>
                                <li class="li6"><span class="s1">procures that the permitted recipient shall, in
                                                relation to any Confidential Information disclosed to it, comply with
                                                these Terms of Use as if it were party thereto; and</span></li>
                        </ol>
                        <li class="li6"><span class="s1">not use or exploit the Confidential Information in any way,
                                        except for the Permitted Use.</span></li>
                </ol>
                <p class="p3">Confidential Information means all confidential information (however recorded or
                        preserved) that is disclosed or made available (in any form or by any method) by Brand Ripplr,
                        an Influencer, or a Campaign Initiator or any member of its group to you or any of your agents
                        in connection with the Content, the Platform or in connection with a Campaign, a brand, or a
                        Campaign Agreement, including:</p>
                <ol class="ol1">
                        <li class="li6"><span class="s1">the fact that discussions or negotiations are taking place
                                        concerning the Campaign or the Campaign Agreement and the content and status of
                                        such discussions or negotiations;</span></li>
                        <li class="li6"><span class="s1">all confidential or proprietary information relating to the
                                        business or affairs, financial or trading position, assets, intellectual
                                        property rights, customers, clients, suppliers, employees, plans, operations,
                                        processes, products, intentions or market opportunities of Brand Ripplr, the
                                        Influencer, the Campaign Initiator or any member of its group;</span></li>
                        <li class="li6"><span class="s1">the Campaign, the Influencer Profile, the Campaign Initiator
                                        Profile, the know-how, designs, trade secrets, technical information or software
                                        of the&nbsp;&nbsp;Campaign Initiator or any member of its group; and</span></li>
                        <li class="li6"><span class="s1">any other information that is identified as being of a
                                        confidential or proprietary nature.</span></li>
                </ol>
                <p class="p2"><strong>Marketing to you</strong></p>
                <p class="p3">We may send you information we think you may find useful or which you have requested from
                        us by push notification and/or (if you provide us with your e-mail address) by e-mail, including
                        information about the latest recommendations or features on Brand Ripplr, provided you have
                        indicated that you do not object to being contacted for these purposes and we will always give
                        you the option to opt-out of receiving further e-mails or push notifications by following the
                        unsubscribe instructions on any communications sent to you. You can also exercise this right at
                        any time by contacting us using the contact details.</p>
                        <section id="cookiesPolicy"></section>
                <p class="p2"><strong>Cookies Policy</strong></p>
                <p class="p3">Cookies/ Web server logs: Similar to other commercial websites, our Website utilises
                        &ldquo;cookies&rdquo; or similar technologies and Web server logs to collect and store
                        information about how our Website is used. Cookies are a feature of Web browser software that
                        allows Web servers to recognise the computer used to access a Web site. Cookies are small pieces
                        of data that are stored by a user&rsquo;s Web browser on the user&rsquo;s hard drive.
                        Information gathered through cookies and Web server logs may include the date and time of
                        visits, the pages viewed, time spent at our Website, and the web sites visited just before and
                        just after our Website. This information is collected on an aggregate basis. None of this
                        information is associated with you as an individual.</p>
                <p class="p3">You can, of course, disable cookies on your computer by indicating this in the preferences
                        or options menus in your browser. However, it is possible that some parts of our Website will
                        not operate correctly if you disable cookies. You should consult with your browser&rsquo;s
                        provider/manufacturer if you have any questions regarding disabling cookies.</p>
                <p class="p3">Third Party Services: We may use services hosted by third parties including but not
                        limited to Google analytics, Inspeclet, AWS to assist in providing our services and to help us
                        understand how our websites are used. These services may collect information sent by a browser
                        as part of a web page request, including IP addresses or cookies. If these third party services
                        collect information, they do so anonymously and in the aggregate to provide information helpful
                        to us such as website trends, without identifying individual visitors. Please see
                        &ldquo;Cookies&rdquo; in the section above for information on how you can control the use of
                        cookies on your computer.</p>
                <p class="p2"><strong>Disclaimer and limitation of liability</strong></p>
                <p class="p3">Each party hereby represents and warrants that: (1) it has the legal right and authority
                        to enter into these Terms of Use; (2) these Terms of Use form a binding legal obligation on
                        behalf of such party; and (3) it has the legal right and authority to perform its obligations
                        under these Terms of Use and to grant the rights and licenses described in these Terms of Use.
                </p>
                <p class="p3">The Platform and the Content are provided &ldquo;as is&rdquo; without warranty or
                        condition of any kind. Use of the Platform or the Content is at your own risk. Brand Ripplr does
                        not make any representations, warranties or conditions about the quality, accuracy, reliability,
                        completeness, currency, or timeliness of the platform or the content. The Platform and the
                        Content may include errors, omissions and inaccuracies, including, without limitation, pricing
                        errors. Brand Ripplr does not assume any responsibility for any errors, omissions or
                        inaccuracies in the platform or the content. In addition, Brand Ripplr expressly reserves the
                        right to correct any pricing errors on the platform.</p>
                <p class="p3">Business and campaign reviews displayed on the site are intended as only general
                        guidelines, and Brand Ripplr does not guarantee the accuracy of the reviews. Brand Ripplr makes
                        no guarantees about the availability of specific businesses, campaigns or types of campaigns.
                </p>
                <p class="p3">To the fullest extent permitted by law, Brand Ripplr disclaims all warranties,
                        representations and conditions of any kind with respect to the Platform, the Content, the
                        Platform Services and any other content whether express, implied, statutory or collateral,
                        including, without limitation, the implied warranties and conditions of merchantability, fitness
                        for a particular purpose and non-infringement or that the platform or the content are or will be
                        error-free or will operate without interruption. In no event will Brand Ripplr be liable,
                        whether based on warranty, contract, tort, negligence, strict liability or any other legal
                        theory, for any damages of any kind (including, without limitation, direct, indirect,
                        incidental, consequential, special, exemplary or punitive damages, lost profits, loss of use,
                        loss of data, personal injury, fines, fees, penalties or other liabilities), whether or not
                        Brand Ripplr is advised of the possibility of such damages, resulting from or related to the use
                        of, or the inability to make use of, the Platform, the Content, the Services or any other
                        content or any campaign agreement.</p>
                <p class="p3">Businesses engaging your services through the Platform are independent organisations and
                        not contractors, agents or employees of Brand Ripplr. Brand Ripplr is not liable for the acts,
                        errors, omissions, representations, warranties, breaches or negligence of any businesses or for
                        any personal injuries, death, property damage, or other damages or expenses resulting therefrom
                        and takes no responsibility whatsoever related to any campaigns or campaign agreements.</p>
                <p class="p3">Some jurisdictions do not allow the exclusion or limitation of liability for consequential
                        or incidental damages, so the above limitation may not apply to you. To the extent that the
                        foregoing limitation does not apply, in no event will the total aggregate liability of Brand
                        Ripplr in connection with or under these terms of use, including from a campaign agreement, or
                        your use of, or inability to make use of, the platform or the content, or for any other claim
                        related in any way to a campaign agreement, or your use of, or inability to make use of, the
                        platform or the content exceed one hundred dollars ($100) or the equivalent in local currency.
                        For greater certainty, the existence of one or more claims under these terms of use will not
                        increase the maximum liability amount.</p>
                <p class="p3">The limitations above reflect the allocation of risk between the parties. The limitations
                        specified in this section will survive and apply even if any limited remedy specified in these
                        terms is found to have failed of its essential purpose.</p>
                <p class="p3">Limitations period. You and Brand Ripplr agree that any cause of action arising out of or
                        related to these Terms of Use or Privacy Policy, the Brand Ripplr Website, the Platform Services
                        or the Campaigns must commence within one (1) year after the cause of action accrues or becomes
                        known to Brand Ripplr. Otherwise, such cause of action is permanently barred.</p>
                <p class="p2"><strong>Indemnification</strong></p>
                <p class="p3">You shall defend, indemnify and hold harmless Brand Ripplr and all of its officers,
                        directors, employees and agents from and against any claims, causes of action, demands,
                        recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature
                        including but not limited to reasonable legal and accounting fees, brought in connection with or
                        as a result of: (a) your breach of any of your warranties, undertakings, representations or
                        obligations under these Terms of Use or any documents referenced herein; (b) your violation of
                        any law (including without limitation any legal requirements or guidelines) or the rights of a
                        third party (including, without limitation, intellectual property rights); (c) your use of the
                        Platform; (d) breach of or failure to perform under any Campaign Agreement by you or by any
                        third party acting on your behalf or with your permission; or (d) the use of any proprietary
                        content, including without limitation design, video, music by you without approval from relevant
                        third parties to do so or the use of any media content created by you, or third parties on your
                        behalf in connection with a Campaign.</p>
                <p class="p2"><strong>Geographic Application of the Platform</strong></p>
                <p class="p3">Not all of the Influencers, Campaigns and Platform Services described on the Platform are
                        available in all jurisdictions. Furthermore, nothing on the Platform constitutes an offer or
                        solicitation to buy or sell any product or service to anyone in any jurisdiction in which such
                        an offer or solicitation is prohibited by law.</p>
                <p class="p3">Please be aware that any information you provide to us may be transferred to the United
                        States and/or third countries. By using our Website, participating in any of our services and/or
                        providing us with your information, you consent to this transfer.</p>
                <p class="p2"><strong>Governing Law</strong></p>
                <p class="p3">These Terms of Use and any action related thereto shall be governed by the laws of United
                        Arab Emirates as applicable in Dubai without regard to its conflict of laws provisions. These
                        laws apply to your access to or use of the Platform or the Content, notwithstanding your
                        domicile, residency or physical location. The Platform and the Content are intended for use only
                        in jurisdictions where they may lawfully be offered for use. Except as restricted by applicable
                        law, you hereby consent to the exclusive jurisdiction and venue of the courts located in Dubai,
                        United Arab Emirates in all disputes arising out of or relating to the use of the Platform or
                        the Content.</p>
                <p class="p2"><strong>Entire Agreement, Waiver and Severability</strong></p>
                <p class="p3">These Terms of Use and this Privacy Policy constitute the entire agreement between Brand
                        Ripplr and you pertaining to the subject matter hereof and supersede all prior or
                        contemporaneous communications and proposals, whether electronic, oral or written, between you
                        and Brand Ripplr with respect to the Platform. A printed version of these Terms of Use and of
                        any notice given in electronic form shall be admissible in judicial or administrative
                        proceedings based upon or relating to these Terms of Use to the same extent and subject to the
                        same conditions as other business documents and records originally generated and maintained in
                        printed form.</p>
                <p class="p3">Brand Ripplr&rsquo;s failure to insist upon or enforce strict performance of any provision
                        of these Terms of Use shall not be construed as a waiver of any provisions or right. The waiver
                        of any such right or provision will be effective only if in writing and signed by a duly
                        authorised representative of Brand Ripplr. Except as expressly set forth in these Terms, the
                        exercise by either party of any of its remedies under these Terms will be without prejudice to
                        its other remedies under these Terms or otherwise.</p>
                <p class="p3">If for any reason a court of competent jurisdiction finds any provision of these Terms of
                        Use invalid or unenforceable, that provision will be enforced to the maximum extent permissible
                        and the other provisions of these Terms will remain in full force and effect.</p>
                <p class="p3">If any of the provisions contained in these Terms of Use conflict with the terms of
                        another agreement between the parties (such as the terms of a Campaign Agreement in respect of a
                        Campaign), then these Terms of Use shall prevail; provided that, if you are also a Campaign
                        Initiator registered on the Platform, the terms of use applicable to Campaign Initiators will
                        govern your relationship with Brand Ripplr in that respect.</p>
                <p class="p3"><strong>Date of Last Revision [July 2021]</strong></p>
        </div>
    
    
    </div>