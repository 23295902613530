import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { Article } from 'src/app/models/article';
import { Title } from '@angular/platform-browser';

@Component({
  selector: "redirect",
  template: "redirecting...",
})
export class ArticleComponent implements OnInit {
  article?: Article;
  param?: string;
  subscription?: Subscription;

  constructor(private route: ActivatedRoute, private appService: AppService, private titleService: Title) { }

  ngOnInit() {
    window.location.href = 'https://news.brandripplr.com'
    // this.param = this.route.snapshot.params.articleId;
    // console.log(this.param);
    // if(this.param) { 
    //   this.subscription = this.appService.getArticle(this.param).subscribe(article => {
    //     this.article = article
    //     this.titleService.setTitle(article.title +" | Brand Ripplr");
    //   });
    // }
  }
  ngOnDestroy() {
    // this.subscription?.unsubscribe();
  }
}