import { Component, Input, OnInit } from '@angular/core';
import { faInstagram, faSnapchat, faSnapchatGhost, faTiktok, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-influencer-social-media-profile',
  templateUrl: './influencer-social-media-profile.component.html',
  styleUrls: ['./influencer-social-media-profile.component.scss']
})
export class InfluencerSocialMediaProfileComponent implements OnInit {

  constructor() { }
  @Input() influencer: any= {};

  faYoutube = faYoutube;
  faTwitter = faTwitter;
  faInstagram = faInstagram;
  faTiktok = faTiktok;
  faSnapchat = faSnapchatGhost;


  ngOnInit(): void {
  }

}
