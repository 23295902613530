import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import { Article } from './models/article';

const newsletterCollection: string = 'newsletter';
const articleTable: string = 'articles';

@Injectable({
    providedIn: 'root',
  })
export class AppService {
    articlesCollection: AngularFirestoreCollection<Article>;
    articles!: Observable<Article[]>;

    constructor(private afs: AngularFirestore) { 
        this.articlesCollection = this.afs.collection(articleTable, ref => ref.orderBy('publishDate','desc'));

        
    }

    addToNewsletter(email: string) {
        console.log(email);
        return new Promise<any>((resolve, reject) => {
            this.afs
                .collection(newsletterCollection)
                .doc(email)
                .set({ email, })
                .then(res => { }, err => reject(err));
        });
    }
    getArticles() {
        this.articles = this.articlesCollection.snapshotChanges().pipe(
            map(
                changes => {
                    return changes.map(a => {
                        const data = a.payload.doc.data() as Article
                        data.id = a.payload.doc.id;
                        data.publishDate = a.payload.doc.get('publishDate').toDate()
                        return data;
                    });
                }
            ))
        return this.articles;
    }
    getArticle(key:string) {
        return this.afs.collection(articleTable).doc(key).snapshotChanges().pipe(
            map( a => {
                const data = a.payload.data() as Article
                data.id = a.payload.id;
                return data;
            })
        )
    }
}

