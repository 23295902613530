import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-influencers',
  templateUrl: './influencers.component.html',
  styleUrls: ['./influencers.component.scss']
})
export class InfluencersComponent implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle("Get paid to work with the brands you love | Brand Ripplr");
  }

  isPlaying: Boolean = false;

  playPause() {
    var myVideo: any = document.getElementById("my_video_1");
    if (myVideo.paused) { 
      this.isPlaying = true;
      myVideo.play();
    }
    else {
      this.isPlaying = false;
      myVideo.pause();
    }
  }

  ngOnInit(): void {
  }

}
