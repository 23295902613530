<div class="leadership-team-member-container">
    <div class="leadership-team-image-container">
        <img src="{{teamMemberImage}}" alt="{{teamMemberName}}" style="width: 100%; height: 100%; border-radius: 20px;"/>
    </div>
    <div class="team-member-name">
        {{teamMemberName}}
    </div>
    <div class="team-member-role">
        {{teamMemberRole}}
    </div>
</div>