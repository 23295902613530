import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-leadership-team-member',
  templateUrl: './leadership-team-member.component.html',
  styleUrls: ['./leadership-team-member.component.scss']
})
export class LeadershipTeamMemberComponent implements OnInit {

  @Input() teamMemberImage = '';
  @Input() teamMemberName = '';
  @Input() teamMemberRole = '';
  constructor() { }

  ngOnInit(): void {
  }

}
