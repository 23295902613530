import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faSquare, faCheckSquare, faClipboardList, faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-signup-popup-button',
  templateUrl: './signup-popup-button.component.html',
  styleUrls: ['./signup-popup-button.component.scss']
})
export class SignupPopupButtonComponent implements OnInit {

  faChevronRight = faChevronRight;

  constructor(private modalService: NgbModal) { }
  showNav: boolean = false;
  @Input() className: string = '';

  toggleNav() {
    if(this.showNav == false) {
      this.showNav = true;
    } else {
      this.showNav = false;
    }
    console.log(this.showNav);
  }

  ngOnInit(): void {
  }

  openVerticallyCentered(content:any) {
    this.modalService.open(content, { centered: true , size: 'lg', scrollable:true});
  }

}
