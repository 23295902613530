import { ChangeDetectorRef, Component, OnInit, } from '@angular/core';
import { FormGroup, FormControl, Validators, } from "@angular/forms";
import { faFacebook, faInstagram, faLinkedin, faTiktok, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faCoffee, faCopyright } from '@fortawesome/free-solid-svg-icons';
import { AppService } from '../app.service';
// import { AppService } from '../app.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  faCopyright = faCopyright;
  faFacebook = faFacebook;
  faYoutube = faYoutube;
  faTwitter = faTwitter;
  faLinkedin = faLinkedin;
  faInstagram = faInstagram;
  faTiktok = faTiktok;

  showEnterValidEmailError: boolean = false;
  showAddedSuccessfully: boolean = false;
  
  d = new Date();
  currentYear = this.d.getFullYear();


  newsletterSubscriptionForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email, Validators.pattern("^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$"), ])
  });

  constructor(private appService: AppService, public cd: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  async onSubmit() {
    this.showEnterValidEmailError = false;
    this.showAddedSuccessfully = false;
    if (this.newsletterSubscriptionForm.valid) {
      this.showAddedSuccessfully = true;
      var res = await this.appService.addToNewsletter(this.newsletterSubscriptionForm.value.email);
    } else {
      this.showEnterValidEmailError = true;
      // console.log(this.newsletterSubscriptionForm);
    }
  }
}