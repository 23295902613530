<div class="page-start">
    <app-header></app-header>
    <div class=" d-flex flex-column align-items-center main-title">
        <div class="headings">
            <h1>Brand Ripplr Platform</h1>
            <p>A bespoke technology that delivers data-driven solutions to maximise real impact for brands.</p>
        </div>
    </div>
</div>
<div class="container features-content">
    <div class="col align-items-center justify-content-center">
        <div class="row justify-content-between">
            <app-feature class="col-md-6" [iconImage]="'vetted-influencers'" [label]="'Influencer Database'"
                [content]="'Access a network of 10M+ global influencers and celebrities and select the relevant and authentic creators.'"></app-feature>

            <app-feature class="col-md-6" [iconImage]="'profile-insights'" [label]="'Influencer Audience Insights'"
            [content]="'Receive strategic audience insights on the influencers including detection of fake followers, brand collaborations and sentiment analysis.'" ></app-feature>

            <app-feature class="col-md-6" [iconImage]="'dashboard'" [label]="'Performance Dashboard'"
            [content]="'Manage and track campaigns in real-time across Instagram, TikTok, YouTube, Snapchat and X via our complimentary dashboard.'"
            ></app-feature>
            <app-feature class="col-md-6" [iconImage]="'manage-campaign'" [label]="'Content Management System'"
                [content]="'View and approve influencer content and assign publish dates.'"
                ></app-feature>
                        
            <app-feature class="col-md-6" [iconImage]="'automated-report'" [label]="'Campaign Reports'"
                [content]="'Detailed analytics and insights on the performance of your campaign to help create a solid strategy for future influencer strategies.'"
                ></app-feature>
        </div>
    </div>
</div>