import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { Article } from 'src/app/models/article';
import { Title } from '@angular/platform-browser';

@Component({
  selector: "redirect",
  template: "redirecting...",
})
export class BlogComponent implements OnInit {
  articles: Article[] | undefined;
  subscription?: Subscription;
  constructor(private appService: AppService, private titleService: Title) {
    this.titleService.setTitle("Brand Ripplr Blog");
  }

  ngOnInit() {
    window.location.href = 'https://news.brandripplr.com'
    // this.subscription = this.appService.getArticles().subscribe(articles => {
    //   this.articles = articles
    // });
  }
  ngOnDestroy() {
    // this.subscription?.unsubscribe();
  }

}
