<div class="page-start">
    <app-header></app-header>
    <div class="d-flex flex-column align-items-center main-title">
        <div class="headings">
            <h1>Get Paid To Work With The Brands You Love</h1>
            <p>Become part of our exclusive network of talented creators and participate in campaigns with international
                brands.</p>
        </div>
        <div class="row gap-5 align-items-center justify-content-center">
            <app-signup-popup-button  class="btn rounded-pill contact-button contact-button-b-red d-flex justify-content-center align-items-center">Sign up for free</app-signup-popup-button>
        </div>
        <div class="page-start-video-container d-flex align-items-center">
            <video style="width: 100%;" (click)="playPause()" id="my_video_1">
                <source src="/assets/for-influencer.mp4#t=0.1" type="video/mp4" />
            </video>
            <button *ngIf="!isPlaying" (click)="playPause()"><img src="assets/images/play.svg"></button>
        </div>
    </div>
</div>
<div class="content" style="margin-top: 45px; text-align: center;">
    <div class="col align-items-center justify-content-center">
        <p style="font-size:30px; font-weight: 900; margin:0">Content creators working with Brand Ripplr</p>
        <div class="row justify-content-around m-auto" style="max-width: 900px;">
            <app-social-media-content [videoName]="'amyroko'" [influencerName]="'Amy Roko'" [videoViews]="'133,445'"
                [videoLikes]="'11,819'" [videoWidth]="600" [videoHight]="340"></app-social-media-content>
            <app-social-media-content [videoName]="'model_roz'" [influencerName]="'Model Roz'" [videoViews]="'79,662'"
                [videoLikes]="'3,000'" [videoWidth]="600" [videoHight]="340"></app-social-media-content>
            <app-social-media-content [videoName]="'mthayelalali'" [influencerName]="'Mthayel Al Ali'"
                [videoViews]="'5,521,954'" [videoLikes]="'231,480'" [videoWidth]="600" [videoHight]="600">
            </app-social-media-content>
        </div>
    </div>
</div>
<div class="container how-it-works">
    <div class="col align-items-center justify-content-center">
        <p style="font-size:34px; font-weight: bold;">How it works</p>
        <div class="row justify-content-center align-items-center" style="margin: 40px auto 0px;">
            <app-campaign-process class="col-md-6" [index]="'1'" [label]="'Apply to join'"
                [content]="'Submit your application to join Brand Ripplr for your chosen social media channel.'">
            </app-campaign-process>
            <app-campaign-process class="col-md-6" [index]="'2'" [label]="'Campaign Matchmaking'"
                [content]="'If accepted, brands can view your profile and invite you to join their campaigns.'">
            </app-campaign-process>
            <app-campaign-process class="col-md-6" [index]="'3'" [label]="'Choose your ripple'"
                [content]="'If the brand is the right fit, accept the campaign and create your content.'">
            </app-campaign-process>
            <app-campaign-process class="col-md-6" [index]="'4'" [label]="'Secure Payment'"
                [content]="'Once the brand approves your content, share it with your followers and receive payment.'">
            </app-campaign-process>
        </div>
    </div>
</div>