<div class="page-start">
    <app-header></app-header>
    <div class="d-flex flex-column align-items-center main-title">
        <div class="headings">
            
            <p>Launching</p>
            
            <h1>Influence For Good</h1>
            <p>Social Impact Initiative</p>
        </div>
    </div>
</div>
<div class="container">
    <p>
        <img src="/assets/images/influence-for-good-logo-1.png" height="110px" />
    </p>
    <strong>What Is Influence For Good?</strong>
    <p>A social impact initiative launched by Brand Ripplr aimed at supporting local and international NGO’s in the region.</p>
    <strong>Our Mission</strong>
    <p>To provide support for NGO’s and organisations through our network of key opinion leaders, content creators and influencers.</p>
    <strong>Our Vision</strong>
    <p>To make a positive impact and real change to communities in the region.</p>
    <strong>Our Partners</strong>
    <p>
        <a href="https://www.unhcr.org/" target="_blank"><img src="/assets/images/unhcr.png" height="100px"></a>
        <a href="https://www.emiratesnaturewwf.ae/en" target="_blank"><img src="/assets/images/emirates_nature_wwf.jpeg" height="100px"></a>
        <a href="https://areekaweb.com/Home/Index" target="_blank"><img src="/assets/images/areeka-web.png" height="50px"></a>
        <a href="https://www.landmarkgroup.com/int/en/home " target="_blank"><img src="/assets/images/landmark.png" height="30px"></a>
    </p>
    <p>
        <a href="mailto:support@brandripplr.com" class="btn rounded-pill contact-button contact-button-b-red" style="line-height: 0.2; padding: 34px 0; width:300px" target="_blank">Get In Touch With Us</a>
    </p>
</div>