<footer>
  <div class="featured-in">
  <h2>Featured On</h2>
    <div class="d-flex justify-content-center ">
      
      <div class="container d-flex row justify-content-around align-items-center" style="height: 100%;">
        <a href="https://www.forbesmiddleeast.com/innovation/startups/uaes-startup-connects-brands-with-influencers-secures-%241m-funding-eying-regional-expansions"
          target="_blank">
          <img src="assets/images/forbes.png" height="15" />
        </a>
        <a href="https://www.entrepreneur.com/article/359348" target="_blank">
          <img src="assets/images/entrepreneur.png" height="20" />
        </a>
        <a href="https://www.menabytes.com/brand-ripplr-series-a/" target="_blank">
          <img src="assets/images/menabyte.png" height="20" />
        </a>
        <a href="https://gulfnews.com/technology/media/with-social-media-influencers-it-is-time-to-think-micro-1.2183417"
          target="_blank">
          <img src="assets/images/gulfnews.png" height="15" />
        </a>
        <a href="http://www.arabianbusiness.com/lists/391075-2018-arab-women24tanaz-dizadji" target="_blank">
          <img src="assets/images/arabian.png" height="20" />
        </a>
      </div>
    </div>
  </div>

  <div class="content" style="text-align: center; width: 100%;">
    <div class="d-flex col align-items-center justify-content-center footer-advocate">
      <div style=" max-width: 800px; ">
        <b style="font-size: 22px; font-weight: bold;text-align: center;">Work with influencers who are ready to
          advocate your brand</b>
      </div>
      <div class="row gap-5 align-items-center justify-content-center" style="margin-top: 20px;">
        <a class="btn rounded-pill contact-button contact-button-b-green d-flex justify-content-around align-items-center"
          href=" https://wa.me/+971526574408" target="_blank">Chat with us</a>
      </div>
    </div>
  </div>

  <div class="footer-links">
    <div class="container">
      <div class="col align-items-center justify-content-around">
        <a class="navbar-brand" routerLink="/">
          <img src="/assets/images/brandripplr.png">
        </a>
        <div class="row">
          <div class="container navbar navbar-expand-lg">
            <ul class="navbar-nav m-auto">
              <!--<li class="nav-item">
                <a class="nav-link" routerLinkActive="activePageLink" routerLink="/">Home <span
                    class="sr-only">(current)</span></a>
              </li>-->
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="activePageLink" routerLink="/about-us">About Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="activePageLink" routerLink="/features">Features</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="activePageLink" routerLink="/services">Services</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="activePageLink" routerLink="/brands-and-agencies">Brands</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="activePageLink" routerLink="/influencers">Influencers</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="activePageLink" routerLink="/influence-for-good">Influence For Good</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://news.brandripplr.com">Industry News</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="activePageLink" routerLink="/contact-us">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row justify-content-center footer-social">
          <div class="social-media-container">
            <a href="https://www.facebook.com/BrandRipplr/" target="_blank">
              <fa-icon [icon]="faFacebook"></fa-icon>
            </a>
          </div>
          <div class="social-media-container">
            <a href="https://www.youtube.com/channel/UCvUNLvL1POHR4BzpXVqUYeA/" target="_blank">
              <fa-icon [icon]="faYoutube"></fa-icon>
            </a>
          </div>
          <div class="social-media-container">
            <a href="https://twitter.com/BrandRipplr" target="_blank">
              <fa-icon [icon]="faTwitter"></fa-icon>
            </a>
          </div>
          <div class="social-media-container">
            <a href="https://www.linkedin.com/company/brandripplr" target="_blank">
              <fa-icon [icon]="faLinkedin"></fa-icon>
            </a>
          </div>
          <div class="social-media-container">
            <a href="https://www.instagram.com/brandripplr/" target="_blank">
              <fa-icon [icon]="faInstagram"></fa-icon>
            </a>
          </div>
          <div class="social-media-container">
            <a href="https://www.tiktok.com/@brandripplr" target="_blank">
              <fa-icon [icon]="faTiktok"></fa-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="newsletter-footer">
    <div class="container row justify-content-between" style="max-width: 1000px;">
      <div class="col-md-5 heading">
        Subscribe to our newsletter
      </div>
      <div class="col-md-6">
        <form [formGroup]="newsletterSubscriptionForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <input type="text" class="form-control" placeholder="name@domain.com" formControlName="email" />
            <button class="btn">
              Subscribe
            </button>
          </div>
          <div *ngIf="showEnterValidEmailError" style="color: #ff3d83;"> Please enter a valid email</div>
          <div *ngIf="showAddedSuccessfully" style="color: #3fc350;">Thank you for subscribing to our newsletter</div>
        </form>
    </div>
  </div>
  </div>

  <div class="copyright">
    <div class="container row  align-items-center justify-content-between">
      <div>
        &copy; {{currentYear}} Brandripplr
      </div>
      <div class="row gap-1">
        <a routerLinkActive="activePageLink" routerLink="/influencer-terms-use"> Terms of Use</a>
        <a routerLink="/influencer-terms-use/" fragment="privacypolicy"> Privacy Policy</a>
        <a routerLink="/influencer-terms-use/" class="hide-mobile" fragment="refundpolicy"> Refund Policy</a>
      </div>
    </div>
  </div>
</footer>