<div class="social-media-profile-container">
    <div class="item">
        <div class="image" [style.background-image]="'url(/assets/images/influencers/' + influencer.image + ')'"></div>
        <div class="name">{{influencer.name}}</div>
        <div class="location">{{influencer.location}}</div>
        <div class="channels">
            <a *ngIf="influencer.instagram" [href]="influencer.instagram" target="_blank">
                <fa-icon class="instagram" [icon]="faInstagram"></fa-icon>
            </a>
            <a *ngIf="influencer.instagram1" [href]="influencer.instagram1" target="_blank">
                <fa-icon class="instagram" [icon]="faInstagram"></fa-icon>
            </a>
            <a *ngIf="influencer.tiktok" [href]="influencer.tiktok" target="_blank">
                <fa-icon class="tiktok" [icon]="faTiktok"></fa-icon>
            </a>
            <a *ngIf="influencer.youtube" [href]="influencer.youtube" target="_blank">
                <fa-icon class="youtube" [icon]="faYoutube"></fa-icon>
            </a>
            <a *ngIf="influencer.snapchat" [href]="influencer.snapchat" target="_blank">
                <fa-icon class="snapchat" [icon]="faSnapchat"></fa-icon>
            </a>
            <a *ngIf="influencer.snapchat1" [href]="influencer.snapchat1" target="_blank">
                <fa-icon class="snapchat" [icon]="faSnapchat"></fa-icon>
            </a>        
        </div>
    </div>
</div>
