import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  faEnvelope = faEnvelope;
  faPhone = faPhone;

  formData = this.builder.group({
    first_name: new FormControl('', [Validators.required]),
    company_name: new FormControl(''),
    type: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    contact_number: new FormControl('', [Validators.required, Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$")]),
    message: new FormControl('', [Validators.required])
    });
  isSubmitted?: boolean;  
  isSending?: boolean;
 
  constructor(private builder: FormBuilder, private func: AngularFireFunctions, private toastr: ToastrService,private spinner: NgxSpinnerService,private titleService: Title) {
    this.titleService.setTitle("Contact Brand Ripplr");
   }

  ngOnInit(): void {
    this.isSending = false;
    this.isSubmitted = false;
  }

  onSubmit(formData: any) {
    this.isSending = true;
    this.spinner.show();
    const callable = this.func.httpsCallable('contactUs');
    callable(formData).toPromise().then(res => {
      console.log(res);
      if(res == 'Sended') {
        this.spinner.hide();
        this.isSubmitted = true;
        this.isSending = false;
      } else {
        this.spinner.hide();
        this.toastr.error('', 'Something went wrong, please try again later');
        this.isSending = false;
      }
    }).catch(res => {
      console.log(res);
      this.spinner.hide();
      this.toastr.error('', 'Something went wrong, please try again later');
      this.isSending = false;
    });
  }

}
