<a (click)="openVerticallyCentered(content)" style="color: white;" class="{{className}}">
  <ng-content></ng-content>
</a>

<ng-template #content let-modal>
  <div class="modal-body">
  <div class="signup-content">
  <div class="signup-business">
  <a href="https://influencer.brandripplr.com/#!/client/connect" target="_blank" class="btn btn-primary signup-popup-button business-button-color">Business platform</a>
    <div>Don't have a business account?</div>
    <a href="/contact-us" target="_blank" style="font-weight: bold;">Book a demo</a>
  </div>
  <div class="or"><span>Or</span></div>
  <div class="signup-influencer">
    <a href="https://influencer.brandripplr.com/#!/connect" target="_blank" class="btn btn-primary signup-popup-button influencer-button-color">Influencer platform</a>
    <div>Don't have an influencer account?</div>
    <a href="https://influencer.brandripplr.com/#!/connect" target="_blank" style="font-weight: bold;">Sign up as an influencer</a>
  </div>
   </div>
  </div>
</ng-template>