// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCUUAkyZbcb_M-cTxdQbRw3vvnFIuXSVn0',
  authDomain: 'brand-ripplr.firebaseapp.com',
  databaseURL: 'https://brand-ripplr-default-rtdb.firebaseio.com',
  projectId: 'brand-ripplr',
  storageBucket: 'brand-ripplr.appspot.com',
  messagingSenderId: '447170222182',
  appId: '1:447170222182:web:9647b07f68a50a889c75a7',
  measurementId: 'G-J0JWM0EZ5V'
};
export const environment = {
  production: true,
  firebase: firebaseConfig,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
