<style>
  .content {
    min-height: calc(100% - (920px));
  }
</style>
<div class="content">
  <router-outlet></router-outlet>
</div>
<!-- Footer -->
<app-footer></app-footer>
<app-cookies-consent></app-cookies-consent>