<div class="page-start">
    <app-header></app-header>
    <div class="d-flex flex-column align-items-center main-title">
        <div class="headings">
            <h1>Partner with top content creators and celebrities</h1>
            <p>We work with leading brands across all industries including FMCG, gaming, parenting, beauty, mobile, fashion, fitness, automotive, telecom, hospitality and government.</p>

        </div>
        <div class="row gap-5 align-items-center justify-content-center">
            <a href="/contact-us" target="_blank" class="btn rounded-pill book-demo-button">Book a demo</a>
        </div>
        <div class="page-start-video-container">
            <video width="280px" height="280px" (click)="playPause()" id="my_video_1">
                <source src="assets/for-clients.mp4#t=0.1" type="video/mp4" />
            </video>
            <button *ngIf="!isPlaying" (click)="playPause()"><img src="assets/images/play.svg"></button>
        </div>
    </div>
</div>
<div>
<div class="container" style="text-align: center; margin: 50px auto;">
    <div class="col align-items-center justify-content-center">
        <p style="font-size:32px; font-weight: bold;">Simplifying The Campaign Process</p>
        <div class="row justify-content-between" style="margin: 45px auto 0px;">
            <p>Brand Ripplr offers end-to-end strategy, creative and campaign management solutions for influencer campaigns via our complimentary dashboard.</p>
            <app-campaign-process class="col-lg-4 col-md-6" [index]="'1'" [label]="'Campaign Brief'"
                [content]="'Define your campaign objectives.'" style="margin:auto"></app-campaign-process>
            <app-campaign-process class="col-lg-4 col-md-6" [index]="'2'" [label]="'Campaign Proposal'"
                [content]="'Review the Brand Ripplr proposal including strategy, content direction, financials, and guaranteed KPIs.'"
                style="margin:auto"></app-campaign-process>
            <app-campaign-process class="col-lg-4 col-md-6" [index]="'3'" [label]="'Influencer Selection'"
                [content]="'Approve confirmed influencers for your campaign and review data on their audience and authenticity.'" style="margin:auto">
            </app-campaign-process>
            <app-campaign-process class="col-lg-4 col-md-6" [index]="'4'" [label]="'Campaign Activation'"
                [content]="'Review the campaign brief for influencers prepared by Brand Ripplr and activate the campaign.'" style="margin:auto">
            </app-campaign-process>
            <app-campaign-process class="col-lg-4 col-md-6" [index]="'5'" [label]="'Content Approval'"
                [content]="'Approve the influencer content on the Brand Ripplr dashboard and allocate your preferred publish dates.'" style="margin:auto">
            </app-campaign-process>
            <app-campaign-process class="col-lg-4 col-md-6" [index]="'6'" [label]="'Campaign Tracking'"
                [content]="'Track campaign performance live on the Brand Ripplr dashboard and receive a detailed analytics report.'"
                style="margin:auto"></app-campaign-process>
        </div>
    </div>
</div>
</div>
<!--<div class="content">
    <div class="col align-items-center justify-content-center trusted-clients">
        <h2>Trusted Clients</h2>
        <div class="row justify-content-around">
            <app-trusted-client-logo *ngFor="let client of clients" [clientImage]="client"></app-trusted-client-logo>
        </div>
    </div>
</div>-->